<template>
  <div class="is-relative">
    <input
      class="input"
      v-bind:placeholder="input_placeholder + input_unit"
      v-bind:id="input_id"
      v-on:keyup="onChange($event)"
      v-bind:value="addCommas(getState[input_id])"
      :type="inputtype"
      :min="min"
      :max="max"
    /><span class="unit">{{ tooltip_unit }}</span>
  </div>
</template>

<script>
import { bus } from "../main";
import { mapState } from "vuex";
export default {
  name: "Select",
  props: ["setup"],
  data: function () {
    return {
      // slider_value: this.setup.start,
      input_id: this.setup.id_tag,
      input_unit: this.setup.unit ? this.setup.unit : "",
      input_placeholder: this.setup.placeholder,
      inputtype: this.setup.inputtype,
      min: this.setup.min,
      max: this.setup.max,
      factor: this.setup.multiplier ? this.setup.multiplier : 1,
      recalculate: this.setup.calculate_on_input === true ? true : false,
      tooltip_unit: this.setup.unit ? " " + this.setup.unit : "",
    };
  },
  methods: {
    onChange: function (event) {
      if (this.input_id === "zielgehalt_brutto") {
        // Zielgehalt brutto soll mindestwert erfüllen und wird sonst nach oben korrigiert
        // Turn off input auto validation, move to button on Step2
        // if (isNaN(event.target.value) || parseFloat(event.target.value.replace(".", "")) < this.min) {
        // setTimeout(() => {
        //   if (parseFloat(event.target.value.replace(".", "")) < this.min) {
        //     event.target.value = this.min
        //   }
        // }, 1000)
        // } else
        {
          this.$store.commit("change_value", {
            id: event.target.id,
            value:
              parseFloat(event.target.value.replace(".", "")) ||
              event.target.value,
          });
        }
      } else if (
        this.input_id !== "rendite" &&
        this.input_id !== "gehaltssteigerung"
      ) {
        var input_value;
        if (event.target.value > 0 && event.target.value !== "undefined") {
          input_value = parseInt(event.target.value.replace(".", ""));
        } else if (isNaN(event.target.value)) {
          input_value = 0;
          event.target.value = 0;
        } else {
          input_value = 0;
          event.target.value = 0;
        }

        //  setTimeout(() => {

        if (
          this.input_id === "wunsch_rentenalter" ||
          this.input_id === "gia_alter" ||
          this.input_id === "auszeit_in_jahre" ||
          this.input_id === "auszeit_monate" ||
          this.input_id === "teilzeit_in_jahre" ||
          this.input_id === "teilzeit_dauer"
        ) {
          setTimeout(() => {
            if (input_value.toString().length > 2) {
              input_value = parseInt(input_value.toString().slice(0, 2));
              event.target.value = parseInt(input_value.toString().slice(0, 2));
            }

            if (
              this.getState.aktives_ziel === "rente" &&
              this.input_id === "wunsch_rentenalter"
            ) {
              if (this.max && input_value > this.max) {
                input_value = this.max;
                event.target.value = this.max;
              } else if (input_value < this.getState.wunsch_rentenalter_min) {
                input_value = this.getState.wunsch_rentenalter_min;
                event.target.value = this.getState.wunsch_rentenalter_min;
              }
            } else {
              if (this.max && input_value > this.max) {
                input_value = this.max;
                event.target.value = this.max;
              } else if (this.min && input_value < this.min) {
                input_value = this.min;
                event.target.value = this.min;
              }
            }
            this.$store.commit("change_value", {
              id: event.target.id,
              value: input_value * this.factor,
            });
            if (this.recalculate) {
              this.$store.commit("init_values", { calctype: "bare" });

              this.$store.commit("calc_sparrate", { calctype: "sparrate" });
              this.$store.commit("init_values", { calctype: "sMonat" });
              this.$store.commit("init_values", { calctype: "sJahr" });
              this.$store.commit("colorbar", {});
            }
          }, 2000);
        } else {
          this.$store.commit("change_value", {
            id: event.target.id,
            value: input_value * this.factor,
          });
          if (this.recalculate) {
            this.$store.commit("init_values", { calctype: "bare" });

            this.$store.commit("calc_sparrate", { calctype: "sparrate" });
            this.$store.commit("init_values", { calctype: "sMonat" });
            this.$store.commit("init_values", { calctype: "sJahr" });
            this.$store.commit("colorbar", {});
          }
        }
      } else {
        this.$store.commit("change_value", {
          id: event.target.id,
          value: parseFloat(event.target.value),
        });
      }
    },

    addCommas: function (nStr) {
      if (
        this.input_id !== "rendite" &&
        this.input_id !== "gehaltssteigerung"
      ) {
        nStr += "";
        var x = nStr.split(".");
        var x1 = x[0];
        var x2 = x.length > 1 ? "." + x[1] : "";
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
          x1 = x1.replace(rgx, "$1" + "." + "$2");
        }
        return (x1 + x2).replace(/,/g, ".");
      } else {
        return nStr;
      }
    },
    addFocusEvent: function (id) {
      var input = document.getElementById(id);
      input.addEventListener("focusout", function (event) {
        console.log("focus left!");
      });
      input.addEventListener("focusin", function (event) {
        console.log("focus active!");
      });
    },
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
  },

  mounted() {
    // this.addFocusEvent(this.setup.id_tag)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.unit {
  position: absolute;
  top: 0.9rem;
  right: 0.3rem;

  @media screen and (min-width: 1408px) {
    top: 0.7rem;
  }
}
</style>
