<template>
  <div id="app">
    <div class="container is-small"></div>
    <div class="container app-wrap">
      <div class="content-tab" id="step1">
        <Step1 @navigate="navigate"></Step1>
      </div>
      <div class="content-tab" id="step2">
        <Step2 @navigate="navigate"></Step2>
      </div>
      <div class="content-tab" id="step3">
        <Step3 @navigate="navigate"></Step3>
      </div>
      <div class="content-tab" id="step4">
        <Step4 @navigate="navigate"></Step4>
      </div>
      <div class="content-tab" id="step5">
        <Step5 @navigate="navigate"></Step5>
      </div>
    </div>
    <Details />
  </div>
</template>

<script>
import { bus } from "./main";
import Step1 from "./steps/Step1.vue";
import Step2 from "./steps/Step2.vue";
import Step3 from "./steps/Step3.vue";
import Step4 from "./steps/Step4.vue";
import Step5 from "./steps/Step5.vue";
import Details from "./components/Details.vue";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "calculator",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Details,
  },
  data: () => {
    return {
      bonus: true,
      active_step: 0,
      total_steps: 5,
      anrede: null,
      logo: null,
    };
  },
  methods: {
    openTab: function (tabNumber) {
      var i, x;

      x = document.getElementsByClassName("content-tab");
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }

      document.querySelector(`#step${tabNumber}`).style.display = "block";
      bus.$emit("calibrate_slider");
      if (tabNumber === 5) {
        this.$store.commit("init_values", { calctype: "bare" });
        this.$store.commit("calc_sparrate", { calctype: "sparrate" });
        this.$store.commit("init_values", { calctype: "sMonat" });
        this.$store.commit("init_values", { calctype: "sJahr" });
        this.$store.commit("colorbar", {});
      }
    },
    navigate: function (next_step) {
      // this.active_step = next_step;
      this.openTab(next_step);
    },
    injectThemesettings: function (idName, settingValue) {
      console.log("injecting", idName, settingValue)
      this.$store.commit("change_value", {
        id: idName,
        value: settingValue,
      });
    },
  },
  created() {
    this.$store.commit("change_value", {
      id: "theme",
      value: {
        color: {
          arrow: arrow_color,
          button: button_color,
        },
        options: {
          auszeit: auszeit,
          rente: rente,
          teilzeit: teilzeit,
          geldImAlter: geldImAlter,
          urlaub: urlaub,
          arbeitgeberzuschuss: arbeitgeberzuschuss,
          arbeitgeberzuschuss_selektor: arbeitgeberzuschuss_selektor,
          ueberstunden: ueberstunden,
          sparen_monat: sparen_monat,
          sparen_jahr: sparen_jahr,
          cta_contact: cta_contact_button,
          position_urlaubstage: position_urlaubstage,
          position_uberstunden: position_uberstunden,
          position_mtl_sparsumme_gehalt: position_mtl_sparsumme_gehalt,
          postion_sonderzahlungen: postion_sonderzahlungen,
          stunden_pro_woche: stunden_pro_woche,
        },
        logo: logo,
        anrede: anrede,
        contact: {
          phone: telephone,
          mail: email,
        },
      },
    });
  
    this.injectThemesettings("ueberstunden_monat_max", ueberstunden_max);
    this.injectThemesettings("ueberstunden_monat_inital", ueberstunden_inital);
    this.injectThemesettings("urlaub_jahr_max", urlaub_max);
    this.injectThemesettings("urlaub_jahr_inital", urlaub_inital);
    this.injectThemesettings("rendite", rendite_inital);
    this.injectThemesettings("bundesland", bundesland_inital);
    this.injectThemesettings("ueberstunden_switch", ueberstunden_switch);
    this.injectThemesettings("sparen_monat_bool", sparen_monat_bool);
    this.injectThemesettings("sparen_monat_prozent", sparen_monat_prozent);
    this.injectThemesettings("arbeitszeit_steps", arbeitszeit_steps);
    this.injectThemesettings("zielwert_regler", zielwert_regler);
    this.injectThemesettings("wochenstunden", stunden_pro_woche);
    this.injectThemesettings(
        "gehaltssteigerung",
        gehaltssteigerung_standardwert
    );
    const list = {
      position_urlaubstage: position_urlaubstage,
      position_uberstunden: position_uberstunden,
      position_mtl_sparsumme_gehalt: position_mtl_sparsumme_gehalt,
      postion_sonderzahlungen: postion_sonderzahlungen
    };
    const sorted_positions = Object.keys(list).sort(function(a,b){return list[a]-list[b]});
    this.injectThemesettings("sorted_positions", sorted_positions);
    this.injectThemesettings("summe_monat_jahr_prozent", summe_monat_jahr_prozent);
    if(arbeitgeberzuschuss_selektor && arbeitgeberzuschuss_selektor.length > 0){
      this.$store.commit("change_value", {
          id: "zuschuss_arbeitgeber",
          value: parseFloat(arbeitgeberzuschuss_selektor[0].wert),
        });
    }
  },
  mounted() {
    bus.$on("toggle_bonus", () => {
      this.bonus = !this.bonus;
    });
    this.openTab(1);
    this.$store.commit("init_time", { calctype: "bare" });
    this.$store.commit("init_values", { calctype: "bare" });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
