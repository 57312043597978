import { calcyAnim } from '../js-components/calcyAnimation';

export default {
  init() {
    // console.log('home');
    calcyAnim();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    // console.log('home after init');
  },
};
