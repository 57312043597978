export function get12MonthRequiredRate (min_rate) {
    return min_rate * 12
}

export function getMonatszins (rendite) {
    return Math.round(
        (Math.pow(1 + rendite / 100, 1 / 12) - 1) * 10000000000
    ) / 100000000
}

export function berechneVorhandenesKapitalInklusiveZinsen (kapital, monatszins, laufzeit) {
    let kapital_vorhanden = kapital
    for (let i; i <= laufzeit; i++) {
        kapital_vorhanden += (kapital_vorhanden * monatszins) / 100
    }
    return kapital_vorhanden
}

export function berechneMonatlicheMindestRate (monatszins, zielkapital, ansparzeit, jahreszins) {
    const zinsfaktor = Math.pow(1 + jahreszins, 1 / 12)
    return monatszins > 0
        ? zielkapital /
        (zinsfaktor *
            ((Math.pow(zinsfaktor, ansparzeit) - 1) / (zinsfaktor - 1)))
        : zielkapital / ansparzeit
}

export function berechneMonatlichenWertUrlaub (wert, anzahlProJahr) {
    return (wert * anzahlProJahr) / 12
}

export function berechneMonatlichenWertUeberstunden (wert, anzahlProMonat) {
    return wert * anzahlProMonat
}

export function istAktivesZielNichtGeldImAlter (aktives_ziel) {
    return aktives_ziel !== "geldImAlter"
}

export function berechneInterpolierteMonatsrate (aktives_ziel, ansparzeit, rate_soll_monat, max_allowed_rate) {
    let neue_soll_rate = rate_soll_monat
    if (istAktivesZielNichtGeldImAlter(aktives_ziel) && ansparzeit >= 12) {
        neue_soll_rate = (rate_soll_monat * ansparzeit) / (ansparzeit - 12)
        if (neue_soll_rate > max_allowed_rate) {
            neue_soll_rate = max_allowed_rate
        }
    } else if (istAktivesZielNichtGeldImAlter(aktives_ziel) && ansparzeit < 12) {
        // TODO: Deal with this error, show Modal not possible
        throw Error("Ansparzeit ist kleiner als 12 Monate")
    } return Math.round(neue_soll_rate)
}

// Hilfsfunktion, um die Sonderzahlung pro Jahr zu berechnen
export function berechneSonderzahlungProJahr (sonderzahlung, gehaltssteigerung, aktivesZiel, jahr) {
    if (jahr % 12 === 0 && aktivesZiel !== "geldImAlter") {
        sonderzahlung += sonderzahlung * (gehaltssteigerung / 100)
    }
    return sonderzahlung
}

// Hilfsfunktion, um die monatliche Sparrate vom Gehalt hinzuzufügen
export function addiereMonatlicheSparrate (betrag, sparen_gehalt) {
    betrag += sparen_gehalt
    return betrag
}

// Hilfsfunktion, um den Kapitalwert und die Rendite des Kapitals zu berechnen
export function berechneKapitalUndRendite (betrag, rendite) {
    const kapital = betrag + betrag * rendite
    const rendite_gewinn = betrag * rendite
    return [kapital, rendite_gewinn]
}

export function berechneBenötigteSparraten (rate_min, stateAsReadOnly) {
    const monatsgehalt = stateAsReadOnly.bruttogehalt_monat
    const jahr_inital = monatsgehalt * stateAsReadOnly.sparrate_jahr_inital
    const jahr_max = monatsgehalt * stateAsReadOnly.sparrate_jahr_max

    // initiale variablen
    let ueberstunden_monat = 0
    let urlaub_jahr = 0
    let sparen_jahr = 0
    let sparen_monat = 0
    let rate_urlaub = 0
    let rate_ueberstunde = 0

    let gesamtGegenwert = (rate_urlaub + rate_ueberstunde + (sparen_jahr > 0 ? sparen_jahr / 12 : 0) + sparen_monat > 0 ? sparen_monat : 0 + stateAsReadOnly.zuschuss_arbeitgeber)
    // calc right amount of sparrate of each props
    while (gesamtGegenwert < rate_min) {
        // 1.urlaub bis zu initialen Wert setzen
        if (stateAsReadOnly.theme.options.urlaub && urlaub_jahr < stateAsReadOnly.urlaub_jahr_inital && istAktivesZielNichtGeldImAlter(stateAsReadOnly.aktives_ziel)) {
            urlaub_jahr += 1
            rate_urlaub = berechneMonatlichenWertUrlaub(stateAsReadOnly.wert_urlaubstag, urlaub_jahr)
            // 2.überstunden bis zu initialen Wert setzen
        } else if (stateAsReadOnly.theme.options.ueberstunden && ueberstunden_monat < stateAsReadOnly.ueberstunden_monat_inital && istAktivesZielNichtGeldImAlter(stateAsReadOnly.aktives_ziel)) {
            ueberstunden_monat += 1
            rate_ueberstunde = berechneMonatlichenWertUeberstunden(stateAsReadOnly.wert_ueberstunde, ueberstunden_monat)
            // 3.jährliche Sparrate bis zu initialen Wert setzen
        } else if (stateAsReadOnly.theme.options.sparen_jahr && (sparen_jahr + 100) <= jahr_inital) {
            sparen_jahr += 100
            // 4.Monatsrate bis zum maximal erlaubten Wert setzen
        } else if (stateAsReadOnly.theme.options.sparen_monat && (sparen_monat + 10) <= stateAsReadOnly.sparen_gehalt_max) {
            sparen_monat += 10
            // 5.Jahresrate bis zum maximal erlaubten Wert setzen
        } else if (stateAsReadOnly.theme.options.sparen_jahr && (sparen_jahr + 100) <= jahr_max) {
            sparen_jahr += 100
            // 6.Urlaub bis zum maximal erlaubten Wert setzen
        } else if (stateAsReadOnly.theme.options.urlaub && urlaub_jahr < stateAsReadOnly.urlaub_jahr_max && istAktivesZielNichtGeldImAlter(stateAsReadOnly.aktives_ziel)) {
            urlaub_jahr += 1
            rate_urlaub = berechneMonatlichenWertUrlaub(stateAsReadOnly.wert_urlaubstag, urlaub_jahr)
            // 7.Ueberstunden bis zum maximal erlaubten Wert setzen
        } else if (stateAsReadOnly.theme.options.ueberstunden && ueberstunden_monat < stateAsReadOnly.ueberstunden_monat_max && istAktivesZielNichtGeldImAlter(stateAsReadOnly.aktives_ziel)) {
            ueberstunden_monat += 1
            rate_ueberstunde = berechneMonatlichenWertUeberstunden(stateAsReadOnly.wert_ueberstunde, ueberstunden_monat)
        } else {
            console.log("Sparziel nicht erreichbar")
            break
        }
        const rate_jahr = sparen_jahr > 0 ? sparen_jahr / 12 : 0
        gesamtGegenwert = (rate_urlaub + rate_ueberstunde + rate_jahr + sparen_monat + stateAsReadOnly.zuschuss_arbeitgeber)
    }

    return {
        sparen_jahr,
        sparen_monat,
        ueberstunden_monat,
        urlaub_jahr
    }
}
