import {
  textMedia,
  heroTextMedia,
  sectionFadeBottomToTop,
  timelineAnimation,
  timelineAnimationLinks,
  timelineAnimationButtons,
  timelineAnimationCardsColor,
  timelineAnimationWithArrows,
  arrowAnimation,
  statment,
} from "./singleAnimations";

export function initializeAnimations() {
  textMedia();
  heroTextMedia();
  sectionFadeBottomToTop();
  timelineAnimation();
  timelineAnimationLinks();
  timelineAnimationButtons();
  timelineAnimationCardsColor();
  timelineAnimationWithArrows();
  arrowAnimation();
  statment();
}
