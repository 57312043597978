import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export function twoOptionsChange() {
    initializeTwoOptionsChange();
    window.addEventListener('resize', function () {
        initializeTwoOptionsChange();
    })
};


export function initializeTwoOptionsChange() {

    if (document.getElementsByClassName('two-options-change').length) {

        const mq = window.matchMedia("(min-width: 1216px)");
        const block1 = document.getElementById("block1");
        const block2 = document.getElementById("block2");
        const rotate = document.querySelector('.rotate');
        // const div = document.querySelector('.sub1');
        const picLeft = document.querySelector('.pic-left');
        const opacity1 = document.querySelector('.opacity1');
        const opacity2 = document.querySelector('.opacity2');
        const moveUp1 = document.querySelector('.move-up1');
        const moveUp2 = document.querySelector('.move-up2');

        if (window.innerWidth < 1216) {
            block1.classList.remove('selected');
            block1.classList.remove('no-selected');
            block2.classList.remove('selected');
            block2.classList.remove('no-selected');
            block1.classList.add('inactive');
            block2.classList.add('inactive');
            block1.addEventListener("mousedown", function () {
                block1.classList.add('inactive');
                block1.classList.remove('selected');
                block1.classList.remove('no-selected');
                block2.classList.add('inactive');
                block2.classList.remove('selected');
                block2.classList.remove('no-selected');
            });

            block2.addEventListener("mousedown", function () {
                block2.classList.add('inactive');
                block2.classList.remove('selected');
                block2.classList.remove('no-selected');
                block1.classList.add('inactive');
                block1.classList.remove('selected');
                block1.classList.remove('no-selected');
            });
        } else {
            block1.classList.remove('inactive');
            block2.classList.remove('inactive');
            block1.addEventListener("mousedown", function () {
                block1.classList.remove('inactive');
                block2.classList.remove('inactive');
                block1.classList.remove('no-selected');
                block1.classList.add('selected');
                block2.classList.remove('selected');
                block2.classList.add('no-selected');
            });

            block2.addEventListener("mousedown", function () {
                block1.classList.remove('inactive');
                block2.classList.remove('inactive');
                block2.classList.remove('no-selected');
                block2.classList.add('selected');
                block1.classList.remove('selected');
                block1.classList.add('no-selected');
            });
        }

        gsap.from(rotate, {
            scrollTrigger: {
                trigger: block1,
                start: 'top bottom-=150',
                end: '+=200',
            },
            rotate: '-45deg',
            duration: 0.5,
            ease: 'none'
        });
    }
}
