<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
<g id="Ebene_1">
	<path :style="style1"  d="M35.657314,19.979198c-10.400797,0-20.926254,2.490919-20.926254,7.245333v4.624441v2.93478v5.81464
		c0,4.762333,10.425907,7.011074,20.826706,7.011074c4.9585,0,14.055038-0.425121,17.070053-3.440136
		c2.123348-2.123352,1.688568-3.812897,3.94907-4.761139v-4.624439v-2.93478v-4.624441
		C56.576889,22.470118,46.051434,19.979198,35.657314,19.979198z"/>
	<path :style="style2"  d="M55.630413,52.653011v2.976513c0,3.383846-8.47863,6.293064-19.513298,6.293064
		c-11.035812,0-20.44976-2.909218-20.44976-6.293064V33.033085 M53.713623,51.097458
		c-0.920677,0.598919-2.147373,1.144512-3.618053,1.617668 M35.559227,54.665356c-10.97506,0-19.872097-2.742222-19.872097-6.124912
		 M52.821808,44.606869c-3.472374,1.785122-9.811108,2.979912-17.054741,2.979912 M20.581753,45.570477
		c-3.047615-1.076309-4.894622-2.484413-4.894622-4.025467 M55.633095,33.089409c0,3.38269-8.946198,6.124908-19.980759,6.124908
		c-11.035702,0-19.981899-2.742218-19.981899-6.124908S24.616634,26.9645,35.652336,26.9645
		C46.686897,26.9645,55.633095,29.70672,55.633095,33.089409z"/>
	<circle :style="style2"  cx="61.066746" cy="46.310463" r="8.320698"/>
	<line :style="style3"  x1="61.066746" y1="43.041618" x2="61.066746" y2="49.579308"/>
	<line :style="style3"  x1="64.335594" y1="46.310463" x2="57.797901" y2="46.310463"/>
	<line :style="style2" x1="55.651352" y1="33.161469" x2="55.651352" y2="39.935867"/>
</g>
<g id="Ebene_2">
</g>
</svg>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GeldImAlter",
  methods: {},
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style1() {
		 return "fill:" + this.getState.theme.color.arrow+" !important; opacity: 0.4";
		
    
    },
    style2() {
       return "fill:none;stroke:" + this.getState.theme.color.arrow+" !important;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;";
	},
	style3() {
	  return "fill:none;stroke:" + this.getState.theme.color.arrow+" !important;stroke-width:1.8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;";
	 
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
