import Vue from "vue"
import Vuex from "vuex"
import { state } from "./store-state"
import {
    berechneVersicherungsPflichtigesEinkommenRV,
    berechneLohnsteuerProJahr,
    berechneSolidaritaetszuschlagProJahr,
    berechneKirchensteuerProJahr,
    berechneBeitragZurRentenversicherungProJahr,
    berechneBeitragZurKrankenversicherungProJahr,
    berechneBeitragZurPflegeversicherungProJahr,
    berechneBeitragZurArbeitslosenVersicherungProJahr,
    berechneVersicherungsPflichtigesEinkommenKV_PV,
    // eslint-disable-next-line no-unused-vars
    getMaxAnrechenbahresBAVfLohnsteuer,
    berechneNettoLohnProJahr
} from "./steuer-constanten-berechnung-2024"
import { lebenserwartung_dav, lebenserwartung_unisex, lebenserwartung_bund, monats_zahl } from "./constants"
import {
    berechneInterpolierteMonatsrate,
    berechneMonatlicheMindestRate,
    berechneVorhandenesKapitalInklusiveZinsen,
    get12MonthRequiredRate,
    getMonatszins,
    istAktivesZielNichtGeldImAlter,
    addiereMonatlicheSparrate,
    berechneBenötigteSparraten
} from "./ratenberechnung"

Vue.use(Vuex)

export const mutations = {
    toggle_zusatz (state) {
        this.state.zusatz_optionen = !this.state.zusatz_optionen
    },
    years (state) {
        const actualYear = new Date().getFullYear()
        const range =
            this.state.aktives_ziel === "rente"
                ? actualYear - this.state.wunsch_rentenalter + 1
                : actualYear - 66
        const age =
            this.state.aktives_ziel === "rente"
                ? 66 - (66 - this.state.wunsch_rentenalter)
                : 66
        this.state.years = Array.from(
            { length: actualYear - range - 17 },
            (value, index) => range + index
        )

        const min_age_retire = actualYear - this.state.geburt_jahr + 1
        this.state.wunsch_rentenalter_min = min_age_retire

        if (!this.state.years.includes(this.state.geburtsjahr)) {
            this.state.geburtsjahr = this.state.years[0]
            this.commit("ziel_in_monate")
        }
    },
    init_time (state) {
        // initialisiert Zeit für input Select
        const month = []
        month[0] = "Januar"
        month[1] = "Februar"
        month[2] = "März"
        month[3] = "April"
        month[4] = "Mai"
        month[5] = "Juni"
        month[6] = "Juli"
        month[7] = "August"
        month[8] = "September"
        month[9] = "Oktober"
        month[10] = "November"
        month[11] = "Dezember"
        const d = new Date()
        this.state.teilnahme_monat = month[d.getMonth()]
        this.state.teilnahme_jahr = d.getFullYear()
        this.state.jahr = d.getFullYear()
        this.state.monat = month[d.getMonth()]
        this.state.datum = d.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
    },
    change_value (state, payload) {
        state[payload.id] = payload.value
        if (payload.id === "zuschuss_arbeitgeber" || payload.id === "zuschuss_arbeitgeber_select") {
            state.zuschuss_arbeitgeber = payload.value
            state.zuschuss_arbeitgeber_select = payload.value
        }
        this.state.isGLS = window.location.hostname.includes("glsbank")
        // Kommt von php
        if (payload.id === "aktives_ziel" && payload.value === "geldImAlter") {
            // eslint-disable-next-line no-undef
            if (this.state.rendite < rendite_inital_gia) {
                // eslint-disable-next-line no-undef
                this.state.rendite = rendite_inital_gia
            }
        } else if (payload.id === "aktives_ziel" && !(payload.value === "geldImAlter")) {
            // eslint-disable-next-line no-undef
            if (this.state.rendite > rendite_inital) {
                // eslint-disable-next-line no-undef
                this.state.rendite = rendite_inital
            }
        }
        if (
            payload.id === "aktives_ziel" ||
            payload.id === "wunsch_rentenalter" ||
            payload.id === "gia_alter"
        ) {
            this.state.keychange += 1
            this.commit("years")
            this.commit("preload_values")
            this.commit("calc_max_values")
        }
        if (payload.id === "aktives_ziel") {
            // TODO check if state resets
            this.state.under70 = false
            this.state.over130 = false
        }
        if (
            (payload.id === "aktives_ziel" && payload.value === "geldImAlter")
        ) {
            this.commit("calc_max_values")
        }
        if (
            (payload.id === "aktives_ziel" && payload.value === "auszeit") ||
            (payload.id === "aktives_ziel" && payload.value === "teilte")
        ) {
            this.state.teilnahme_jahr = this.state.jahr
            this.state.teilnahme_monat = this.state.monat
        }
        if (payload.id === "privat_versichert") {
            if (payload.value > 0) {
                this.state.gesetzlich_versichert = false
                this.state.gesetzlich_versichert_go = "Nein"
            } else if (payload.value === 0) {
                this.state.gesetzlich_versichert = true
                this.state.gesetzlich_versichert_go = "Ja"
            }
        }
        if (payload.id === "kirchensteuer") {
            if (payload.value === "Nein") {
                this.state.kirchensteuer = false
                this.state.kirchensteuer_go = "Nein"
            } else if (payload.value === "Ja") {
                this.state.kirchensteuer = true
                this.state.kirchensteuer_go = "Ja"
            }
        }
        if (payload.id === "steuerklasse") {
            this.state.steuerklasse = payload.value
        }
        if (payload.id === "bundesland") {
            this.state.bundesland = payload.value
        }
        if (payload.id === "kinder") {
            this.state.kinder = payload.value
        }
        if (payload.id === "kirchensteuer") {
            this.state[payload.id] = payload.value !== "Nein"
        }
        if (payload.id === "gesetzlich_versichert_go") {
            this.state.gesetzlich_versichert =
                payload.value !== "Nein"
        }
        if (payload.id === "gesetzlich_versichert_go") {
            this.state.gesetzlich_versichert =
                payload.value !== "Nein"
        }
        if (payload.id === "theme.options.ueberstunden") {
            this.state.theme.options.ueberstunden = payload.value !== "Nein"
        }
        if (payload.id === "sparen_gehalt") {
            this.state.sparen_gehalt = payload.value
            this.commit("init_values", {
                calctype: "sMonat"
            })
            this.commit("colorbar")
        }
        if (payload.id === "sparen_gehalt_last_year") {
            this.state.sparen_gehalt_last_year = payload.value
            this.commit("init_values", {
                calctype: "sMonat"
            })
            this.commit("colorbar")
        }
        if (payload.id === "sparen_jahr") {
            this.state.sparen_jahr = payload.value
            this.commit("init_values", {
                calctype: "sJahr"
            })
            this.commit("colorbar")
        }
        if (
            payload.id === "zuschuss_arbeitgeber" ||
            payload.id === "zuschuss_arbeitgeber_select" ||
            payload.id === "rendite" ||
            payload.id === "urlaub_jahr" ||
            payload.id === "ueberstunden_monat"
        ) {
            this.commit("init_values", {
                calctype: "sJahr"
            })
            this.commit("init_values", {
                calctype: "sMonat"
            })
            this.commit("colorbar")
        }
        if (payload.id === "bruttogehalt_monat") {
            this.state[payload.id] = payload.value
            this.state.JAHRESLOHN_BRUTTO = payload.value * 12
            // Wenn im Backend Reglerwerte definiert sind zeigen wir den Prozent Slider an
            // eslint-disable-next-line no-undef
            if ((zielwert_regler.minimalwert_zielregler && zielwert_regler.minimalwert_zielregler > 0 && zielwert_regler.maximalwert_zielregler && zielwert_regler.maximalwert_zielregler > 0)) {
                this.state.zielgehalt_brutto = Math.round(
                    (payload.value / 100) * this.state.gehalt_prozent
                )
                // wenn nicht dann zeigen wir ein Input Feld für das Ziehgehalt an
            } else if (this.state.zielgehalt_brutto) {
                this.state.gehalt_prozent = Math.round((this.state.zielgehalt_brutto / payload.value) * 100)
            }
        }
        if (payload.id === "zielgehalt_brutto") {
            this.state[payload.id] = payload.value
            this.state.zielgehalt_brutto = payload.value
            if (this.state.bruttogehalt_monat) {
                this.state.gehalt_prozent = Math.round((payload.value / this.state.bruttogehalt_monat) * 100)
            }
        }
        if (payload.id === "gehalt_prozent") {
            this.state[payload.id] = payload.value
        }
        if (payload.id === "zusatzrente") {
            this.state.zusatzrente = payload.value
        }
    },
    init_values (_state, payload) {
        this.commit("years")
        this.commit("lebenserwartung")
        this.commit("renteneintritt")
        this.commit("ziel_in_monate", payload)
        this.commit("gehaltssteigerung")
        this.commit("calc_max_values")

        this.commit("wert_arbeit")
        this.commit("calc_auszeit", payload)
        this.commit("dauerZiel", payload)

        this.commit("calc_sparrate", payload)
        this.commit("kapital_notwendig", payload)

        this.commit("berechneNetto", payload)
        this.commit("berechneSparenNetto", payload)
        this.commit("berechneLetzesJahrNetto", payload)

        this.commit("sparen", payload)
        this.commit("rendite_rente")
        this.commit("fill_circle", payload)
        this.commit("steuerersparnis")
        this.commit("detail_values")
    },
    preload_values () {
        if (this.state.aktives_ziel === "geldImAlter") {
            this.state.ueberstunden_monat = null
            this.state.urlaub_jahr = null
        } else {
            if (this.state.theme && this.state.theme.options && this.state.theme.options.urlaub) {
                this.state.urlaub_jahr = this.state.urlaub_jahr_inital
            } else {
                this.state.urlaub_jahr = 0
            }
            if (this.state.theme && this.state.theme.options && this.state.theme.options.ueberstunden) {
                this.state.ueberstunden_monat = this.state.ueberstunden_monat_inital
            } else {
                this.state.ueberstunden_monat = 0
            }
        }
    },
    berechneNetto () {
        this.state.JAHRESLOHN_BRUTTO = this.state.bruttogehalt_monat * 12
        this.state.LOHNSTEUER = berechneLohnsteuerProJahr(this.state.JAHRESLOHN_BRUTTO,
            this.state.gesetzlich_versichert,
            this.state.steuerklasse,
            this.state.bundesland,
            this.state.kinder,
            this.state.geburt_jahr,
            this.state.steuerfreibetrag)

        const nettoLohnProJahr = berechneNettoLohnProJahr(this.state.JAHRESLOHN_BRUTTO, this.state.gesetzlich_versichert, this.state.steuerklasse, this.state.bundesland, this.state.kinder, this.state.geburt_jahr, this.state.steuerfreibetrag, this.state.kirchensteuer)
        // Monats netto
        this.state.netto_monat = Math.round(nettoLohnProJahr / 12 * 100) / 100
    },
    berechneSparenNetto (_state, payload) {
        const calctype = payload.calctype ? payload.calctype : null
        this.state.JAHRESLOHN_BRUTTO = this.state.bruttogehalt_monat * 12
        if (calctype === "sMonat" || calctype === "sJahr") {
            const sparen_jahresgehalt = (calctype === "sMonat") ? this.state.sparen_gehalt * 12 : this.state.sparen_jahr

            // Für sonderzahlung ist brutto sparen, gleich des jahres brutto da dieses keine sonderzahlung/einmalzahlungen berücksichtigt.
            this.state.JAHRESLOHN_BRUTTO_SPAREN =
                (calctype === "sMonat") ? this.state.JAHRESLOHN_BRUTTO - sparen_jahresgehalt : this.state.JAHRESLOHN_BRUTTO

            let versicherungspflichtigesEinkommenRV = berechneVersicherungsPflichtigesEinkommenRV(this.state.JAHRESLOHN_BRUTTO_SPAREN, this.state.bundesland, 0)
            let versicherungsPflichtigesEinkommenKV_PV = berechneVersicherungsPflichtigesEinkommenKV_PV(this.state.JAHRESLOHN_BRUTTO_SPAREN, 0)
            if (this.state.aktives_ziel === "geldImAlter") {
                // für geld im Alter sind nur 4% BBGW sozialversicherungsfrei
                if (calctype === "sMonat") {
                    // Für Monat abgezogen von gesamt brutto
                    versicherungspflichtigesEinkommenRV = berechneVersicherungsPflichtigesEinkommenRV(this.state.JAHRESLOHN_BRUTTO, this.state.bundesland, sparen_jahresgehalt)
                    versicherungsPflichtigesEinkommenKV_PV = berechneVersicherungsPflichtigesEinkommenKV_PV(this.state.JAHRESLOHN_BRUTTO, sparen_jahresgehalt)
                } else {
                    // Für Sonderzahlungen abgezogen von gesamt brutto + sonderzahlungen
                    versicherungspflichtigesEinkommenRV = berechneVersicherungsPflichtigesEinkommenRV((this.state.JAHRESLOHN_BRUTTO + sparen_jahresgehalt), this.state.bundesland, sparen_jahresgehalt)
                    versicherungsPflichtigesEinkommenKV_PV = berechneVersicherungsPflichtigesEinkommenKV_PV((this.state.JAHRESLOHN_BRUTTO + sparen_jahresgehalt), sparen_jahresgehalt)
                }
            }
            this.state.LOHNSTEUER_SPAREN = berechneLohnsteuerProJahr(this.state.JAHRESLOHN_BRUTTO_SPAREN,
                this.state.gesetzlich_versichert,
                this.state.steuerklasse,
                this.state.bundesland,
                this.state.kinder,
                this.state.geburt_jahr,
                this.state.steuerfreibetrag)
            this.state.KIRCHENSTEUER_SPAREN = 0
            if (this.state.kirchensteuer) {
                this.state.KIRCHENSTEUER_SPAREN = berechneKirchensteuerProJahr(this.state.LOHNSTEUER_SPAREN, this.state.bundesland)
            }
            this.state.RENTENVERSICHERUNG_SPAREN = berechneBeitragZurRentenversicherungProJahr(versicherungspflichtigesEinkommenRV, this.state.bundesland)
            this.state.KRANKENVERSICHERUNG_SPAREN = berechneBeitragZurKrankenversicherungProJahr(versicherungsPflichtigesEinkommenKV_PV)
            this.state.PFLEGEVERSICHERUNG_SPAREN = berechneBeitragZurPflegeversicherungProJahr(
                versicherungsPflichtigesEinkommenKV_PV,
                this.state.bundesland,
                this.state.geburt_jahr,
                this.state.kinder)
            this.state.ARBEITSLOSENVERSICHERUNG_SPAREN = berechneBeitragZurArbeitslosenVersicherungProJahr(versicherungspflichtigesEinkommenRV, this.state.bundesland)
            this.state.SOLIDARITAETSZUSCHLAG_SPAREN = berechneSolidaritaetszuschlagProJahr(this.state.LOHNSTEUER_SPAREN, this.state.steuerklasse)

            // Jahres netto sparen
            const netto = Math.round(
                (this.state.JAHRESLOHN_BRUTTO_SPAREN -
                    this.state.LOHNSTEUER_SPAREN -
                    this.state.SOLIDARITAETSZUSCHLAG_SPAREN -
                    this.state.KIRCHENSTEUER_SPAREN -
                    this.state.RENTENVERSICHERUNG_SPAREN -
                    this.state.KRANKENVERSICHERUNG_SPAREN -
                    this.state.PFLEGEVERSICHERUNG_SPAREN -
                    this.state.ARBEITSLOSENVERSICHERUNG_SPAREN) * 100) / 100

            // Monats netto sparen
            const netto_monat = Math.round(netto / 12 * 100) / 100

            if (payload.calctype === "sJahr" && this.state.sparen_jahr > 0) {
                this.state.netto_sJahr = netto
                const jahresbruttoInclusiveEinmalzahlung = this.state.JAHRESLOHN_BRUTTO + this.state.sparen_jahr
                // volles Netto inklusive Sonderzahlung/Einmalzahlung
                this.state.netto_jahr = berechneNettoLohnProJahr(jahresbruttoInclusiveEinmalzahlung, this.state.gesetzlich_versichert, this.state.steuerklasse, this.state.bundesland, this.state.kinder, this.state.geburt_jahr, this.state.steuerfreibetrag, this.state.kirchensteuer)
                this.state.netto_diff_jahr = Math.round((this.state.netto_jahr - this.state.netto_sJahr) * 100) / 100
            } else if (payload.calctype === "sMonat" && this.state.sparen_gehalt > 0) {
                this.state.netto_sMonat = netto_monat
                this.state.netto_diff_monat = Math.round((this.state.netto_monat - this.state.netto_sMonat) * 100) / 100
            }
        }
    },
    berechneLetzesJahrNetto () {
        // For 70(130) percent rule
        if (this.state.sparen_gehalt_last_year > 0) {
            const nettoLohnJahrMitMinSparrate = berechneNettoLohnProJahr((this.state.JAHRESLOHN_BRUTTO - this.state.sparen_gehalt_last_year * 12),
                this.state.gesetzlich_versichert,
                this.state.steuerklasse,
                this.state.bundesland,
                this.state.kinder,
                this.state.geburt_jahr,
                this.state.steuerfreibetrag,
                this.state.kirchensteuer)
            this.state.netto_monat_last_year = Math.round(nettoLohnJahrMitMinSparrate / 12 * 100) / 100
            this.state.netto_diff_last_year = Math.round((this.state.netto_monat - this.state.netto_monat_last_year) * 100) / 100
        } else this.state.netto_diff_last_year = 0
    },
    renteneintritt () {
        let renteneintritt
        if (this.state.geburt_jahr < 1955) {
            renteneintritt = 1955
        } else if (
            this.state.geburt_jahr <= 1964 &&
            this.state.geburt_jahr >= 1955
        ) {
            renteneintritt = this.state.geburt_jahr
        } else if (this.state.geburt_jahr > 1964) {
            renteneintritt = 1964
        }
        this.state.renteneintritt = renteneintritt
    },
    ziel_in_monate (state, payload) {
        const current_date = new Date()
        let ansparzeit
        const difference =
            this.state.teilnahme_jahr * 12 +
            monats_zahl[this.state.teilnahme_monat] -
            (current_date.getFullYear() * 12 + current_date.getMonth()) -
            1
        if (
            this.state.aktives_ziel === "auszeit" ||
            this.state.aktives_ziel === "teilzeit"
        ) {
            ansparzeit =
                (this.state.aktives_ziel === "auszeit"
                    ? parseInt(this.state.auszeit_in_monate) + this.state.auszeit_in_jahre * 12 - difference
                    : parseInt(this.state.teilzeit_in_monate) + this.state.teilzeit_in_jahre * 12) - difference
        } else if (this.state.aktives_ziel === "rente") {
            const alter =
                current_date.getFullYear() * 12 +
                current_date.getMonth() -
                (this.state.geburt_jahr * 12 +
                    monats_zahl[this.state.geburt_monat])
            ansparzeit = this.state.wunsch_rentenalter * 12 - alter - difference
            const beginnRenteMonatsZahl = (monats_zahl[this.state.geburt_monat] + 1) % 12
            this.state.einstiegs_monat_fuer_rente = Object.keys(monats_zahl).find(key => monats_zahl[key] === beginnRenteMonatsZahl)
            this.state.einstiegs_monat_fuer_rente = monats_zahl[this.state.geburt_monat] + 1
        } else if (this.state.aktives_ziel === "geldImAlter") {
            const time =
                parseInt(this.state.geburt_jahr) + parseInt(this.state.gia_alter)
            const jahre = time - this.state.teilnahme_jahr
            ansparzeit =
                jahre * 12 +
                Math.abs(
                    monats_zahl[this.state.teilnahme_monat] -
                    monats_zahl[this.state.geburt_monat]
                )
        }

        this.state.ansparzeit_monate = ansparzeit
        const beginnRenteMonatsZahl = (monats_zahl[this.state.geburt_monat] + 1) % 12
        this.state.einstiegs_monat_fuer_rente = Object.keys(monats_zahl).find(key => monats_zahl[key] === beginnRenteMonatsZahl)
    },
    calc_max_values () {
        this.state.sparen_jahr_max =
            this.state.bruttogehalt_monat * this.state.sparrate_jahr_max
        if (this.state.aktives_ziel === "geldImAlter") {
            this.state.sparen_gehalt_max = this.state.bruttogehalt_monat
        } else {
            let maxAusZeitSparrateLetztesJahr = Math.round((this.state.letztes_gehalt - (this.state.zielgehalt_brutto_with_gehaltssteigerung / 1.3)))
            const minAusZeitSparrateLetzesJahr = Math.round((this.state.letztes_gehalt - (this.state.zielgehalt_brutto_with_gehaltssteigerung / 0.7)))
            this.state.sparen_gehalt_last_year_min = minAusZeitSparrateLetzesJahr > 0 ? minAusZeitSparrateLetzesJahr : 0
            let maxAusZeitSparrate = this.state.bruttogehalt_monat - 550
            if (this.state.sparen_monat_bool && this.state.sparen_monat_prozent > 0) {
                maxAusZeitSparrate = this.state.bruttogehalt_monat * (this.state.sparen_monat_prozent / 100)
            }

            if (maxAusZeitSparrateLetztesJahr < 0) {
                maxAusZeitSparrateLetztesJahr = 0
                this.state.over130 = true
                this.state.under70 = false
                this.state.notPossible = false
            } else if (this.state.sparen_gehalt_last_year_min > 0) {
                this.state.under70 = true
                this.state.over130 = false
                // Check if this configuration is possible with values from backend
                if (!this.state.theme.options.sparen_monat || this.state.theme.options.sparen_monat === "false") {
                    this.state.notPossible = true
                } else if (maxAusZeitSparrate < minAusZeitSparrateLetzesJahr) {
                    this.state.notPossible = true
                } else {
                    this.state.notPossible = false
                }
            } else {
                this.state.under70 = false
                this.state.over130 = false
                this.state.notPossible = false
            }

            this.state.sparen_gehalt_max = maxAusZeitSparrate
            this.state.sparen_gehalt_last_year_max = maxAusZeitSparrate < maxAusZeitSparrateLetztesJahr ? maxAusZeitSparrate : maxAusZeitSparrateLetztesJahr
            // Update calculation if sparen_gehalt_last_year_max is smaller than sparen_gehalt or sparen_gehalt_last_year_min is bigger than sparen_gehalt
            if (this.state.sparen_gehalt_last_year_min <= this.state.sparen_gehalt && this.state.sparen_gehalt <= this.state.sparen_gehalt_last_year_max) {
                this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = false
                this.state.sparen_gehalt_last_year = 0
            } else if (this.state.sparen_gehalt_last_year_min >= 0 && this.state.sparen_gehalt_last_year_min > this.state.sparen_gehalt &&
                !(this.state.sparen_gehalt_last_year >= this.state.sparen_gehalt_last_year_min)) {
                this.state.sparen_gehalt_last_year = this.state.sparen_gehalt_last_year_min
                this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = true
            } else if (this.state.sparen_gehalt_last_year_max >= 0 && this.state.sparen_gehalt_last_year_max < this.state.sparen_gehalt &&
                !(this.state.sparen_gehalt_last_year >= this.state.sparen_gehalt_last_year_min && this.state.sparen_gehalt_last_year <= this.state.sparen_gehalt_last_year_max)) {
                this.state.sparen_gehalt_last_year = this.state.sparen_gehalt_last_year_max
                this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = true
            }
            if (this.state.sparen_gehalt_last_year_min > this.state.sparen_gehalt_last_year_max) {
                this.state.notPossible = true
            }
        }
    },

    gehaltssteigerung () {
        let letztes_gehalt = this.state.bruttogehalt_monat
        const ganze_jahre = Math.floor(this.state.ansparzeit_monate / 12)
        for (let i = 0; i < ganze_jahre; i++) {
            letztes_gehalt += letztes_gehalt * (this.state.gehaltssteigerung / 100)
        }
        this.state.letztes_gehalt = letztes_gehalt
        this.state.zielgehalt_brutto_with_gehaltssteigerung = Math.round((this.state.gehalt_prozent / 100) * letztes_gehalt)
    },
    sparen (state, payload) {
        this.state.rendite_gewinn = 0
        this.state.summe_monat = 0
        this.state.summe_nMonat = 0
        this.state.summe_sonderzahlung = 0
        this.state.summe_nSonderzahlung = 0
        const sparzeit_monate = this.state.ansparzeit_monate
        const netto_monat = this.state.netto_diff_monat
        const netto_jahr = this.state.netto_diff_jahr
        let betrag = this.state.vorhandenes_guthaben
        let kapital_monatsrate = 0
        let kapital_nMonatsrate = 0
        let kapital_monatsrateLetzesJahr = 0
        let kapital_nMonatsrateLetzesJahr = 0
        let kapital_nSonderzahlung = 0
        let kapital_sonderzahlung = 0
        let kapital_arbeitgeberzuschuss = 0
        let kapital_ueberstunden = 0
        let kapital_urlaubstage = 0
        const rendite = this.state.rendite_monat / 100
        let rendite_gewinn = 0
        let urlaubstag = this.state.wert_urlaubstag
        let ueberstunde = this.state.wert_ueberstunde

        for (let month = 1; month <= sparzeit_monate; month++) {
            if (month % 12 === 0) {
                // addiere Sonderzahlung pro Jahr

                if (!(this.state.aktives_ziel === "geldImAlter")) {
                    urlaubstag += urlaubstag * (this.state.gehaltssteigerung / 100)
                    ueberstunde += ueberstunde * (this.state.gehaltssteigerung / 100)
                }
            }

            // addiere Sonderzahlung Jahr - auf Monate gerechnet

            betrag += this.state.sparen_jahr / 12
            kapital_sonderzahlung += this.state.sparen_jahr / 12
            kapital_nSonderzahlung += netto_jahr / 12

            if (!(this.state.aktives_ziel === "geldImAlter")) {
                // addiere abteilig Urlaub
                betrag += (this.state.urlaub_jahr * urlaubstag) / 12
                kapital_urlaubstage += (this.state.urlaub_jahr * urlaubstag) / 12
                // addiere Überstunden pro Monat
                betrag += this.state.ueberstunden_monat * ueberstunde
                kapital_ueberstunden += this.state.ueberstunden_monat * ueberstunde
            }

            // addiere monatliche Sparrate vom Gehalt unter Berücksichtigung einer eventuellen fixen Sparrate für das letzte Jahr

            // For 70(130) percent rule
            if (istAktivesZielNichtGeldImAlter(this.state.aktives_ziel) &&
                month > sparzeit_monate - 12 && (this.state.sparen_gehalt_last_year_min > this.state.sparen_gehalt || this.state.sparen_gehalt > this.state.sparen_gehalt_last_year_max) && this.state.sparen_gehalt_last_year > 0) {
                betrag = addiereMonatlicheSparrate(betrag, this.state.sparen_gehalt_last_year)
                kapital_monatsrateLetzesJahr += this.state.sparen_gehalt_last_year
                kapital_nMonatsrateLetzesJahr += this.state.netto_diff_last_year
            } else if (this.state.sparen_gehalt > 0) {
                betrag = addiereMonatlicheSparrate(betrag, this.state.sparen_gehalt)
                kapital_monatsrate += this.state.sparen_gehalt
                kapital_nMonatsrate += netto_monat
            }
            // addiere Arbeitgeberzushuss pro Monat
            betrag += this.state.zuschuss_arbeitgeber

            kapital_arbeitgeberzuschuss += this.state.zuschuss_arbeitgeber
            // addiere monatliche rendite

            rendite_gewinn += betrag * rendite
            betrag += betrag * rendite
        }

        if (this.state.aktives_ziel === "geldImAlter") {
            const rentendauer_monate = this.state.rentendauer * 12
            const renten_kapital = Math.round(betrag / 100) * 100 - Math.round(this.state.kapital_notwendig / 100) * 100
            let renten_rendite = 0
            for (let month = 1; month <= rentendauer_monate; month++) {
                renten_rendite += renten_kapital * (this.state.rendite_monat / 100)
            }
            this.state.rendite_ueberschuss_rente = renten_rendite > 0 ? Math.round(renten_rendite / 100) * 100 : 0
        } else {
            this.state.rendite_ueberschuss_rente = 0
        }

        this.state.kapital = Math.round(betrag / 100) * 100
        this.state.rendite_gewinn = Math.round(rendite_gewinn / 100) * 100
        this.state.summe_monat = Math.round(kapital_monatsrate / 100) * 100
        this.state.summe_monatLetztesJahr = Math.round(kapital_monatsrateLetzesJahr / 100) * 100
        this.state.summe_nMonat = Math.round(kapital_nMonatsrate / 100) * 100
        this.state.summe_nMonatLetztesJahr = Math.round(kapital_nMonatsrateLetzesJahr / 100) * 100
        this.state.summe_sonderzahlung = Math.round(kapital_sonderzahlung / 100) * 100
        this.state.summe_nSonderzahlung = Math.round(kapital_nSonderzahlung / 100) * 100
        this.state.summe_arbeitgeber = Math.round(kapital_arbeitgeberzuschuss / 100) * 100
        this.state.summe_urlaubstage = Math.round(kapital_urlaubstage / 100) * 100
        this.state.summe_ueberstunden = Math.round(kapital_ueberstunden / 100) * 100
    },
    lebenserwartung () {
        let lebenserwartung
        if (this.state.sterbetafel === "DAV2004R") {
            lebenserwartung = lebenserwartung_dav
        } else if (this.state.sterbetafel === "Unisex-Tarif Versicherer") {
            lebenserwartung = lebenserwartung_unisex
        } else if (
            this.state.sterbetafel === "Allgemeine Sterbetafel Bundesamt"
        ) {
            lebenserwartung = lebenserwartung_bund
        }
        this.state.lebenserwartung = lebenserwartung
    },
    dauerZiel () {
        let rentendauer
        if (this.state.aktives_ziel === "rente") {
            rentendauer =
                this.state.rente_einstiegsalter[this.state.renteneintritt] -
                this.state.wunsch_rentenalter * 12 -
                1
        } else if (this.state.aktives_ziel === "geldImAlter") {
            rentendauer =
                this.state.lebenserwartung[this.state.gia_alter][
                    this.state.geschlecht
                ] * 12
        }

        let monat
        if (this.state.aktives_ziel === "rente") {
            monat =
                ((this.state.rente_einstiegsalter[this.state.renteneintritt] - 1) /
                    12 -
                    Math.floor(
                        (this.state.rente_einstiegsalter[this.state.renteneintritt] - 1) /
                        12
                    )) *
                12
        } else if (this.state.aktives_ziel === "auszeit") {
            monat =
                (this.state.auszeit_monate / 12 -
                    Math.floor(this.state.auszeit_monate / 12)) *
                12
        } else if (this.state.aktives_ziel === "teilzeit") {
            monat =
                (this.state.teilzeit_dauer / 12 -
                    Math.floor(this.state.teilzeit_dauer / 12)) *
                12
        } else {
            monat = 0
        }

        let add_jahr = 0
        this.state.renteneintritt_jahr =
            parseInt(this.state.geburt_jahr) + parseInt(this.state.wunsch_rentenalter)
        // Ende Vorruhestand
        this.state.rentenaustritt_jahr =
            parseInt(this.state.geburt_jahr) +
            Math.floor(
                this.state.rente_einstiegsalter[this.state.renteneintritt] / 12
            )

        if ((monat === 0)) {
            if (this.state.aktives_ziel === "rente") {
                this.state.renteneintritt_monat = this.state.geburt_monat
            } else if (this.state.aktives_ziel === "auszeit") {
                this.state.ende_auszeit_monat = this.state.monat
            } else if (this.state.aktives_ziel === "teilzeit") {
                this.state.ende_teilzeit_monat = this.state.monat
            } else {
                this.state.ende_gia_monat = this.state.geburt_monat
            }
        } else {
            const numToString = []
            numToString[0] = "-"
            numToString[1] = "Januar"
            numToString[2] = "Februar"
            numToString[3] = "März"
            numToString[4] = "April"
            numToString[5] = "Mai"
            numToString[6] = "Juni"
            numToString[7] = "Juli"
            numToString[8] = "August"
            numToString[9] = "September"
            numToString[10] = "Oktober"
            numToString[11] = "November"
            numToString[12] = "Dezember"

            let count
            if (this.state.aktives_ziel === "rente") {
                count = monats_zahl[this.state.geburt_monat]
            } else {
                count = monats_zahl[this.state.monat]
            }
            let i = monat
            for (i; i > 0; i--) {
                if (count + 1 > 12) {
                    count = 1
                    add_jahr++
                } else {
                    count++
                }
            }

            if (this.state.aktives_ziel === "rente") {
                const overflow = (monats_zahl[this.state.geburt_monat] + (this.state.rente_einstiegsalter[this.state.renteneintritt]) % 12)
                if (overflow > 12) {
                    this.state.rentenaustritt_jahr = this.state.rentenaustritt_jahr + 1
                }
                const rentenAustrittMonat = overflow % 12
                // Letzer Monat vorruhestand
                this.state.rentenaustritt_monat = Object.keys(monats_zahl).find(key => monats_zahl[key] === rentenAustrittMonat)
            } else if (this.state.aktives_ziel === "auszeit") {
                this.state.ende_auszeit_monat = numToString[count]
            } else {
                this.state.ende_teilzeit_monat = numToString[count]
            }
        }
        this.state.renteneintritt_jahr =
            parseInt(this.state.geburt_jahr) + parseInt(this.state.wunsch_rentenalter) +
          (monats_zahl[this.state.geburt_monat] === 12 ? 1 : 0)
        if (this.state.aktives_ziel !== "rente") {
            this.state.rentenaustritt_jahr =
            parseInt(this.state.geburt_jahr) +
            add_jahr +
            Math.floor(
                this.state.rente_einstiegsalter[this.state.renteneintritt] / 12
            )
        }
        this.state.ende_teilzeit_jahr =
            parseInt(this.state.jahr) +
            parseInt(this.state.teilzeit_in_jahre) +
            Math.floor(this.state.teilzeit_dauer / 12) +
            add_jahr
        this.state.ende_auszeit_jahr =
            parseInt(this.state.jahr) +
            parseInt(this.state.auszeit_in_jahre) +
            Math.floor(this.state.auszeit_monate / 12) +
            add_jahr
        this.state.ende_gia_jahr =
            parseInt(this.state.geburtsjahr) + parseInt(this.state.gia_alter + rentendauer / 12)

        this.state.rentendauer = rentendauer / 12
    },
    rendite_rente () {
        const monatszins =
            Math.round(
                (Math.pow(1 + this.state.rendite / 100, 1 / 12) - 1) * 10000000000
            ) / 100000000

        if (this.state.aktives_ziel === "geldImAlter") {
            const rentendauer_monate = this.state.rentendauer * 12
            let renten_rendite = 0
            let renten_kapital = this.state.kapital
            const renten_auszahlung = this.state.zusatzrente

            for (let month = 1; month <= rentendauer_monate; month++) {
                renten_kapital -= renten_auszahlung
                if (renten_kapital < 0) {
                    renten_kapital = 0
                }
                renten_rendite += renten_kapital * (monatszins / 100)
            }
            this.state.rendite_gewinn_rente =
                Math.round(renten_rendite / 100) * 100
        } else {
            this.state.rendite_gewinn_rente = 0
        }
    },
    kapital_notwendig (state, payload) {
        if (this.state.aktives_ziel === "rente") {
            this.state.kapital_notwendig =
                Math.round(
                    (this.state.rentendauer *
                        12 *
                        (this.state.zielgehalt_brutto_with_gehaltssteigerung)) /
                    100
                ) * 100
        } else if (this.state.aktives_ziel === "geldImAlter") {
            this.state.kapital_notwendig = this.state.rendite > 0
                ? (
                    Math.round(
                        (this.state.zusatzrente *
                            (1 -
                                Math.pow(
                                    1 + this.state.rendite_monat / 100,
                                    this.state.rentendauer * 12 + 1
                                ))) /
                        (Math.pow(
                            1 + this.state.rendite_monat / 100,
                            this.state.rentendauer * 12
                        ) *
                            (1 - (1 + this.state.rendite_monat / 100))) /
                        100
                    ) * 100)
                : this.state.zusatzrente * (this.state.rentendauer * 12 + 1)
        }
    },

    fill_circle (state, payload) {
        let kapital_soll
        if (this.state.aktives_ziel === "rente") {
            kapital_soll = this.state.kapital_notwendig
        } else if (this.state.aktives_ziel === "geldImAlter") {
            kapital_soll = this.state.kapital_notwendig
        } else {
            kapital_soll = this.state.zielkapital
        }
        const rendite_ueberschuss_rente =
            this.state.rendite_ueberschuss_rente > 0
                ? this.state.rendite_ueberschuss_rente
                : 0

        let prozent = Math.round(
            ((this.state.kapital + rendite_ueberschuss_rente) / kapital_soll) * 100
        )
        this.state.ziel_prozent = prozent

        // Show Tipp for Überschuss durch Mindestsparrate ?
        let ueberschuss = 0
        if (this.state.ziel_prozent > 100 && (this.state.aktives_ziel === "auszeit" || this.state.aktives_ziel === "teilzeit") && this.state.sparen_gehalt_last_year_min > 0) {
            ueberschuss = Math.floor(
                (this.state.kapital - this.state.zielkapital) /
                100
            ) * 100
        } else if (this.state.ziel_prozent > 100 && this.state.aktives_ziel === "rente" && this.state.sparen_gehalt_last_year_min > 0) {
            ueberschuss = Math.floor(
                (this.state.kapital +
                    this.state.rendite_ueberschuss_rente -
                    this.state.kapital_notwendig) /
                100
            ) * 100
        }
        // boolean if we show the tip in results or not
        this.state.showTip = ueberschuss > this.state.zielgehalt_brutto_with_gehaltssteigerung
        let circle
        if (prozent < 100) {
            circle = prozent
        } else if (prozent < 0) {
            prozent = 0
            circle = 0
        } else {
            prozent = 100
            circle = 100
        }
        this.state.anzeige_prozent = prozent

        if (document.getElementById("circle2")) {
            document.getElementById("circle2").style.strokeDashoffset =
                510 - (510 * circle) / 100
        }
        if (document.getElementById("circle3")) {
            document.getElementById("circle3").style.strokeDashoffset =
                510 - (510 * circle) / 200
        }
    },
    steuerersparnis () {
        const ansparzeit = this.state.ansparzeit_monate
        let ersparnis_monat = 0
        let ersparnis_jahr = 0
        // Not over 130% and not under 70% Rule
        if (istAktivesZielNichtGeldImAlter(this.state.aktives_ziel) && !(this.state.sparen_gehalt_last_year_min > this.state.sparen_gehalt || this.state.sparen_gehalt > this.state.sparen_gehalt_last_year_max)) {
            ersparnis_monat = this.state.sparen_gehalt > 0
                ? (this.state.sparen_gehalt - this.state.netto_diff_monat) *
                ansparzeit
                : 0
        } else {
            ersparnis_monat = this.state.sparen_gehalt > 0
                ? (this.state.sparen_gehalt - this.state.netto_diff_monat) *
                (ansparzeit - 12)
                : 0
            ersparnis_monat += this.state.sparen_gehalt_last_year > 0 ? (this.state.sparen_gehalt_last_year - this.state.netto_diff_last_year) * 12 : 0
        }
        if (this.state.sparen_jahr > 0) {
            ersparnis_jahr = ((this.state.sparen_jahr - this.state.netto_diff_jahr) / 12) *
                ansparzeit
        }
        this.state.steuerersparnis =
            Math.round((ersparnis_jahr + ersparnis_monat) / 100) * 100
    },

    colorbar () {
        const kapital_ges = this.state.kapital + this.state.steuerersparnis
        let summe_nettoEinzahlungMonatGes = this.state.summe_nettoEinzahlungMonat > 0 ? this.state.summe_nettoEinzahlungMonat : 0
        summe_nettoEinzahlungMonatGes += this.state.summe_nettoEinzahlungMonatLetztesJahr > 0 ? this.state.summe_nettoEinzahlungMonatLetztesJahr : 0

        this.state.colorbar.green =
            (this.state.summe_urlaubstage / kapital_ges) * 100
        this.state.colorbar.brown =
            (this.state.summe_ueberstunden / kapital_ges) * 100

        this.state.colorbar.yellow =
            summe_nettoEinzahlungMonatGes <= 0
                ? 0
                : (summe_nettoEinzahlungMonatGes / kapital_ges) * 100
        this.state.colorbar.orange =
            this.state.summe_nettoSonderzahlungJahr <= 0
                ? 0
                : (this.state.summe_nettoSonderzahlungJahr / kapital_ges) * 100
        this.state.colorbar.red =
            ((this.state.rendite_gewinn + this.state.rendite_gewinn_rente) /
                kapital_ges) *
            100
        this.state.colorbar.purple =
            (this.state.steuerersparnis / kapital_ges) * 100
        this.state.colorbar.blue =
            (this.state.summe_arbeitgeber / kapital_ges) * 100
        this.state.colorbar.lightblue =
            (this.state.vorhandenes_guthaben / kapital_ges) * 100
    },

    // ---------------Kalkulation Auszeit & Teilzeit
    calc_auszeit (state, payload) {
        if (
            this.state.aktives_ziel === "auszeit" ||
            this.state.aktives_ziel === "teilzeit"
        ) {
            this.commit("zielkapital")
        }
    },

    zielkapital () {
        const auszeit =
            this.state.aktives_ziel === "auszeit"
                ? this.state.auszeit_monate
                : this.state.teilzeit_dauer
        const monatsgehalt = this.state.letztes_gehalt
        const wochenstunden = this.state.wochenstunden
        const teilzeitstunden = this.state.teilzeit_stunden
        let zielkapital
        if (this.state.aktives_ziel === "auszeit") {
            zielkapital = this.state.zielgehalt_brutto_with_gehaltssteigerung * auszeit
        } else if (this.state.aktives_ziel === "teilzeit") {
            zielkapital =
                auszeit * this.state.zielgehalt_brutto_with_gehaltssteigerung -
                ((auszeit * monatsgehalt) / wochenstunden) * teilzeitstunden
            if (zielkapital < 0) {
                zielkapital = 0
            }
        }
        this.state.zielkapital = zielkapital
    },
    wert_arbeit () {
        if (!(this.state.aktives_ziel === "geldImAlter")) {
            this.state.wert_urlaubstag = parseFloat(
                (this.state.bruttogehalt_monat / ((5 * 13) / 3)).toFixed(2)
            )
            this.state.wert_ueberstunde = parseFloat(
                (
                    this.state.bruttogehalt_monat /
                    ((this.state.wochenstunden * 13) / 3)
                ).toFixed(2)
            )
        }
    },
    detail_values () {
        this.state.details.input =
            (this.state.summe_monat > 0 ? this.state.summe_nMonat : 0) + (this.state.summe_monatLetztesJahr > 0 ? this.state.summe_nMonatLetztesJahr : 0) +
            (this.state.summe_sonderzahlung > 0 ? this.state.summe_nSonderzahlung : 0) + this.state.vorhandenes_guthaben

        this.state.details.output =
            this.state.details.input +
            this.state.summe_ueberstunden +
            this.state.summe_urlaubstage +
            this.state.steuerersparnis +
            this.state.rendite_gewinn +
            this.state.summe_arbeitgeber

        // clean out summe_N
        this.state.summe_nMonat = this.state.summe_monat > 0 ? this.state.summe_nMonat : 0
        this.state.summe_nMonatLetztesJahr = this.state.summe_nMonatLetztesJahr > 0 ? this.state.summe_nMonatLetztesJahr : 0
        this.state.summe_nSonderzahlung = this.state.summe_sonderzahlung > 0 ? this.state.summe_nSonderzahlung : 0

        this.state.summe_nettoEinzahlungMonat = this.state.summe_nMonat
        this.state.summe_nettoEinzahlungMonatLetztesJahr = this.state.summe_nMonatLetztesJahr
        this.state.summe_nettoSonderzahlungJahr = this.state.summe_nSonderzahlung
        this.state.netto_zahlen_monat = Math.round(this.state.sparen_gehalt > 0 ? this.state.netto_diff_monat : 0)
        this.state.netto_zahlen_monat_last_year = Math.round(this.state.sparen_gehalt_last_year > 0 ? this.state.netto_diff_last_year : 0)
        this.state.netto_zahlen_sonderzahlungJahr = Math.round(this.state.sparen_jahr > 0 ? this.state.netto_diff_jahr : 0)
    },
    calc_sparrate (state, payload) {
        let zielkapital
        const ansparzeit = this.state.ansparzeit_monate
        const jahreszins = this.state.rendite / 100
        const monatszins = getMonatszins(this.state.rendite)
        // this.state.sparen_gehalt_last_year = 0
        this.state.rendite_monat = monatszins
        const kapital_vorhanden = berechneVorhandenesKapitalInklusiveZinsen(this.state.vorhandenes_guthaben, monatszins, ansparzeit)

        if (this.state.aktives_ziel === "geldImAlter" || this.state.aktives_ziel === "rente") {
            zielkapital = this.state.kapital_notwendig - kapital_vorhanden
        } else {
            zielkapital = this.state.zielkapital - kapital_vorhanden
        }

        let sparen_im_letzen_jahr = 0

        // gilt nur bei Auszahlungszielen unter 70 mit Mindestsparrate, im Verhältnis zur Höhe monatlichen des Gehalts im letzen Sparjahr
        if (istAktivesZielNichtGeldImAlter(this.state.aktives_ziel) && this.state.sparen_gehalt_last_year_min && this.state.under70) {
            sparen_im_letzen_jahr = get12MonthRequiredRate(this.state.sparen_gehalt_last_year_min)
        }

        // Monaliche Sparrate, welche abgedeckt werden muss ohne letzes Jahr
        const rate_min = berechneMonatlicheMindestRate(monatszins, zielkapital, ansparzeit, jahreszins)

        if (payload.calctype === "sparrate") {
            if (sparen_im_letzen_jahr >= zielkapital) {
                // Assign to state
                this.state.sparen_jahr = 0
                this.state.sparen_gehalt = 0
                this.state.ueberstunden_monat = 0
                this.state.urlaub_jahr = 0
                this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = true
                this.state.sparen_gehalt_last_year = this.state.sparen_gehalt_last_year_min
            } else {
                const res = berechneBenötigteSparraten(rate_min, this.state)
                if (res.sparen_monat >= this.state.sparen_gehalt_last_year_min && res.sparen_monat <= this.state.sparen_gehalt_last_year_max) {
                    // Assign to state
                    this.state.sparen_jahr = res.sparen_jahr
                    this.state.sparen_gehalt = res.sparen_monat
                    this.state.ueberstunden_monat = res.ueberstunden_monat
                    this.state.urlaub_jahr = res.urlaub_jahr
                    this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = false
                    this.state.sparen_gehalt_last_year = 0
                } else if (res.sparen_monat < this.state.sparen_gehalt_last_year_min) {
                    // Ergebnis 2: Monatliche Sparrate ist unter der Mindestsparrate
                    const rate_min_abzueglich_letzes_jahr = berechneMonatlicheMindestRate(monatszins, (zielkapital - sparen_im_letzen_jahr), ansparzeit, jahreszins)
                    const res_2 = berechneBenötigteSparraten(rate_min_abzueglich_letzes_jahr, this.state)
                    this.state.sparen_jahr = res_2.sparen_jahr
                    this.state.sparen_gehalt = berechneInterpolierteMonatsrate(this.state.aktives_ziel, ansparzeit, res_2.sparen_monat, this.state.sparen_gehalt_max) || 0
                    this.state.ueberstunden_monat = res_2.ueberstunden_monat
                    this.state.urlaub_jahr = res_2.urlaub_jahr
                    this.state.sparen_gehalt_last_year = this.state.sparen_gehalt_last_year_min
                    this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = true
                } else if (res.sparen_monat > this.state.sparen_gehalt_last_year_max) {
                    // Ergebnis 3: Monatliche Sparrate ist über der Maximalen Sparrate
                    const sparen_im_letzen_jahr_max = get12MonthRequiredRate(this.state.sparen_gehalt_last_year_max)
                    const rate_min_abzueglich_letzes_jahr = berechneMonatlicheMindestRate(monatszins, (zielkapital - sparen_im_letzen_jahr_max), ansparzeit, jahreszins)
                    const res_3 = berechneBenötigteSparraten(rate_min_abzueglich_letzes_jahr, this.state)
                    this.state.sparen_jahr = res_3.sparen_jahr
                    this.state.sparen_gehalt = berechneInterpolierteMonatsrate(this.state.aktives_ziel, ansparzeit, res_3.sparen_monat, this.state.sparen_gehalt_max) || 0
                    this.state.ueberstunden_monat = res_3.ueberstunden_monat
                    this.state.urlaub_jahr = res_3.urlaub_jahr
                    this.state.sparen_gehalt_last_year = this.state.sparen_gehalt_last_year_max
                    this.state.andere_rate_fuer_sparen_gehalt_letzes_jahr = true
                }
            }
        }
    }
}

export default new Vuex.Store({
    loading: false,
    state: state,
    mutations: mutations,
    getters: {
        getState: (state) => {
            return state
        }
    },
    actions: {
        post: () => {
        }
    },
    modules: {}
})
