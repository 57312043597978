<template>
  <section class="section">
    <div class="container is-medium">
      <div class="columns is-3 is-multiline step-container">
        <div class="column is-12-touch is-8-desktop">
          <div class="part-left">
            <div class="container">
              <div class="subline is-relative">
                <Arrow />
                <span v-html="returnHtml(txtString.step3.headline) || ''">
                  <!-- Headline left -->
                </span>
              </div>
            </div>
            <div class="container">
              <div class="control">
                <div class="columns">
                  <div class="column is-8">
                    <Select
                      :setup="select1"
                      :options="months"
                      :key="getState.keychange + 'month'"
                    />
                  </div>

                  <div class="column is-4">
                    <Select
                      :setup="select2"
                      :options="getState.years"
                      :key="getState.keychange + 'year'"
                    />
                  </div>
                </div>
              </div>
              <div
                v-html="returnHtml(txtString.step3.left.select1) || ''"
                class="description"
              >
                <!-- Geburtstag -->
              </div>
            </div>
            <div
              v-if="
                getState.aktives_ziel === 'auszeit' ||
                getState.aktives_ziel === 'rente' ||
                getState.aktives_ziel === 'teilzeit'
              "
              class="container"
            >
              <Slider :setup="slider2" :key="getState.keychange + 'slide2'" />
              <div
                v-html="returnHtml(txtString.step3.left.slider1) || ''"
                class="description_slider"
              >
                <!-- Arbeitsstunden/Woche -->
              </div>
            </div>

            <div
              class="container"
              v-if="
                getState.aktives_ziel !== 'geldImAlter' &&
                getState.ueberstunden_switch
              "
            >
              <Select
                :setup="select3"
                :options="boolean"
                ref="theme.options.ueberstunden"
              />
              <div
                v-html="returnHtml(txtString.step3.left.select3) || ''"
                class="description"
              >
                <!-- Überstundenkonto? -->
              </div>
            </div>

            <div class="container">
              <Textinput :setup="textinput1" />
              <div
                v-html="returnHtml(txtString.step3.left.textinput1) || ''"
                class="description"
              >
                <!-- Bruttogehalt/Monat -->
              </div>
            </div>
            <div class="container" v-if="getState.theme.options.arbeitgeberzuschuss_selektor && getState.theme.options.arbeitgeberzuschuss_selektor.length > 1">
              <Select
                :setup="select_arbeitgeberzuschuss"
                :options="getState.theme.options.arbeitgeberzuschuss_selektor"
                :key="getState.keychange + 'zuschuss_arbeitgeber_select'"
              />
              <div
                v-html="returnHtml(txtString.step3.left.select_arbeitgeberzuschuss) || ''"
                class="description"
              >
                <!-- Arbeitgeberzuschuss? -->
              </div>
            </div>
            <div class="container">
              <Slider :setup="slider1" ref="slideninja" />
              <div
                v-html="returnHtml(txtString.step3.left.slider2) || ''"
                class="description_slider"
              >
                <!-- Steuerklasse -->
              </div>
            </div>

            <div class="level is-mobile">
              <div
                v-html="returnHtml(txtString.general.button.back) || ''"
                class="level-left button back"
                v-on:click="navigate(2)"
              >
                <!-- Button Zurück -->
              </div>
              <div
                v-html="returnHtml(txtString.general.button.calculate) || ''"
                :style="forwardButton"
                class="level-right button forward"
                v-on:click="navigate(5)"
              >
                <!-- Button Berechnen -->
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12-touch is-4-desktop">
          <div class="fix-safari-desktop">
          <div class="part-right">
            <div class="is-relative">
              <Arrow />
              <span v-html="returnHtml(txtString.step3.right.headline) || ''">
                <!-- Headline right -->
              </span>
              <p v-html="returnHtml(txtString.step3.right.text) || ''">
                <!-- Text right -->
              </p>
            </div>

            <div class="bottom">
              <div
                v-html="returnHtml(txtString.general.button.more) || ''"
                class="forward_bg"
                v-on:click="navigate(4)"
              >
                <!-- Button Weitere Details -->
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <ModalNotPossible v-if="showModalNotPossible" @click-sparzielAendern="navigate(2, true)"></ModalNotPossible>
    <ModalRechnerOver130Under70 v-else-if="showModal" @click-sparzielAendern="navigate(2, true)" @click-zumErgebnis="navigate(5, true)"></ModalRechnerOver130Under70>
  </section>
</template>
<script>
import Slider from "../components/Slider.vue"
import Select from "../components/Select.vue"
import Arrow from "../svg/Arrow.vue"
import Textinput from "../components/Textinput.vue"
import ModalRechnerOver130Under70 from "../components/ModalOver130Under70.vue"
import ModalNotPossible from "../components/ModalNotPossible.vue"
export default {
    name: "Step3",
    components: {
        ModalNotPossible,
        ModalRechnerOver130Under70,
        Slider,
        Select,
        Textinput,
        Arrow
    },
    data: () => {
        return {
            bonus: true,
            modalOver130: null,
            slider1: {
                min: 1,
                max: 6,
                step: 1,
                start: 1,
                id_tag: "steuerklasse"
            },
            slider2: {
                min: 10,
                max: 50,
                step: arbeitszeit_steps === "dezimal_step" ? 0.1 : arbeitszeit_steps === "quarter_step" ? 0.25 : 1,
                id_tag: "wochenstunden"
            },
            select1: { id_tag: "geburt_monat" },

            select2: { id_tag: "geburt_jahr" },
            textinput1: {
                id_tag: "bruttogehalt_monat",
                placeholder: "2700",
                unit: "€"
            },
            select3: {
                id_tag: "theme.options.ueberstunden"
            },
            select_arbeitgeberzuschuss: {
                id_tag: "zuschuss_arbeitgeber_select"
            },
            anrede: null,
            showModal: false,
            showModalNotPossible: false
        }
    },
    methods: {
        navigate: function (direction, fromModal) {
            // Recalculate on navigate to check for rules
            this.$store.commit("init_values", { calctype: "bare" })
            this.$store.commit("calc_sparrate", { calctype: "sparrate" })
            this.$store.commit("init_values", { calctype: "sMonat" })
            this.$store.commit("init_values", { calctype: "sJahr" })
            this.$store.commit("colorbar", {})
            if (!fromModal && this.getState.notPossible && direction === 5) {
                this.openModalNotPossible()
            } else if (!fromModal && (this.getState.over130 || this.getState.under70) && direction === 5) {
                this.openModal()
            } else {
                this.closeModal()
                this.closeModalNotPossible()
                this.$emit("navigate", direction)
            }
            document.getElementById("app").scrollIntoView()
        },
        toggleBonus: function () {
            this.$store.commit("toggle_zusatz")
        },
        openModal () {
            this.showModal = true
        },
        closeModal () {
            this.showModal = false
        },
        openModalNotPossible () {
            this.showModalNotPossible = true
        },
        closeModalNotPossible () {
            this.showModalNotPossible = false
        }
    },
    computed: {
        getState () {
            return this.$store.getters.getState
        },
        years () {
            // MOVED INTO VUEX STORE
        },
        boolean () {
            return ["Ja", "Nein"]
        },
        months () {
            const allMonths = [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember"
            ]
            return allMonths
        },
        forwardButton () {
            return (
                "color: " +
        this.getState.theme.color.arrow +
        " ; border-color: " +
        this.getState.theme.color.arrow
            )
        }
    },
    mounted () {},
    created () {}
}
</script>
<style scoped lang="scss">
.section {
  padding: 0;
  .title {
    display: flex;
    justify-content: center;
    width: auto;
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 600;
  }
}

</style>
