export function pardotFormular() {
  console.log(steven_loadmore_params.ajaxurl);
  const sectionForm = document.querySelector(".pardot-formular");
  if (sectionForm) {
    const form = sectionForm.querySelector("#pardot-form");
    const requiredError = sectionForm.querySelector('.required-error').innerHTML;
    const mailError = sectionForm.querySelector('.mail-error').innerHTML;
    const submitBtn = form.querySelector("#submit-pardot");

    function isEmail(email) {
      var regex =
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    };
    function showError(input, message) {
      const formField = input.parentElement;
      formField.classList.remove("success");
      formField.classList.add("error");

      const error = formField.querySelector(".message");
      error.textContent = message;
    };
    function showSuccess(input) {
      const formField = input.parentElement;
      formField.classList.remove("error");
      formField.classList.add("success");

      const error = formField.querySelector(".message");
      error.textContent = "";
    };
    function isChecked(input) {
      if (!input.checked) {
        showError(input, requiredError);
      } else {
        showSuccess(input);
        input.value = input.checked;
      }
    };
    function hasSuccessClass(input) {
      return input.parentElement.classList.contains("success");
    };
    function checkInputText(input) {
      const inputValue = input.value.trim();
      var numUpper = (inputValue.match(/[A-Z]/g) || []).length;    // 2
      if (inputValue == "") {
        showError(input, requiredError);
      } else {
        showSuccess(input);
        // more than 2 uppercase logic
        // check for CAPS LOCK
        // if (inputValue.length === numUpper) {
        //   showSuccess(input);
        // } else {
        //   // if not all UPPERCASE limit chars
        //   if (numUpper < 2) {
        //     showSuccess(input);
        //   }
        // }

      }
    };
    function checkEmail(input) {
      const emailValue = input.value.trim();
      if (emailValue == "") {
        showError(input, requiredError);
      } else if (!isEmail(emailValue)) {
        showError(input, mailError);
      } else {
        showSuccess(input);
      }
    };
    submitBtn.addEventListener("click", function (e) {
      const inputs = [...form.querySelectorAll("input")];

      inputs.forEach((input) => {
        if (input.required) {
          switch (input.type) {
            case "text":
              checkInputText(input);
              break;
            case "email":
              checkEmail(input);
              break;
            case "checkbox":
              isChecked(input);
          }
        }
      });
      isCaptchaValid();
      let requiredInputs = inputs.filter(function (input) {
        return input.required;
      });
     function isCaptchaValid () {
        const captcha = form.querySelector(".h-captcha");
        const captchaIframe = captcha.querySelector("iframe");
        let captchaResponse = captchaIframe.getAttribute("data-hcaptcha-response");
        if (captchaResponse === "") {
          showError(captcha, requiredError);
          return false
        } else {
          showSuccess(captcha);
          return true
        }
      };
     
   
      let isFormValid = requiredInputs.every(hasSuccessClass) ;
      if (isFormValid == true  && isCaptchaValid()) {
        let formData = new FormData(form);
        const postID = form.dataset.postId;

        formData.append( 'action', 'pardot' );
        formData.append( 'postID', postID );

        const dankeUrl = document.querySelector('.link-danke').innerHTML;

        fetch(gp_params.ajaxurl, {
          method: 'POST',
          body: formData
        })    
        .then((response) => response.text())
        .then((data) => {
          // here display the data u get from the backend
          if (data) {
            console.log(data);
            setTimeout(() => {
              window.location.replace(dankeUrl);
            }, 1500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    });
  }
}
