export function CircleFirefox() {
    const circle = document.getElementsByClassName('firefoxMax')[0];

    if (circle && window.innerWidth <= 768) {
        circle.classList.add('firefoxMin');
    } else {
        window.onresize = function () {
            if (circle && window.innerWidth < 768) {
                circle.classList.add('firefoxMin');
            }
        }
    }
};