export function FAQ() {
  const items = document.querySelectorAll(".accordion-item");

  function closeAllAccordions(exceptItem = null) {
    document
      .querySelectorAll(".accordion-content.active")
      .forEach((content) => {
        if (content.closest(".accordion-item") !== exceptItem) {
          content.style.maxHeight = null;
          content.classList.remove("active");

          const video = content.querySelector("video");
          if (video) {
            video.pause();
          }

          const iconContainer = content
            .closest(".accordion-item")
            .querySelector(".accordion-icon");
          iconContainer.querySelector(".icon-plus").style.display = "inline";
          iconContainer.querySelector(".icon-minus").style.display = "none";
        }
      });
  }

  items.forEach((item) => {
    item.addEventListener("click", function (event) {
      if (
        event.target.closest("video, a, .iframe-box") ||
        event.target.classList.contains("accordion-icon")
      ) {
        return;
      }

      const target = item.querySelector(".accordion-content");
      const isActive = target.classList.contains("active");
      const iconContainer = item.querySelector(".accordion-icon");

      closeAllAccordions(item);

      if (!isActive) {
        target.style.maxHeight = target.scrollHeight + "px";
        iconContainer.querySelector(".icon-plus").style.display = "none";
        iconContainer.querySelector(".icon-minus").style.display = "inline";
      } else {
        target.style.maxHeight = null;
        iconContainer.querySelector(".icon-plus").style.display = "inline";
        iconContainer.querySelector(".icon-minus").style.display = "none";

        const video = target.querySelector("video");
        if (video) {
          video.pause();
        }
      }

      target.classList.toggle("active");
    });
  });

  window.addEventListener("resize", () => {
    document
      .querySelectorAll(".accordion-content.active")
      .forEach((content) => {
        content.style.maxHeight = content.scrollHeight + "px";
      });
  });
}
