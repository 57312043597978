<template>
  <div class="is-relative">
    <div class="input" id="year-month-input">
    <input
      v-bind:value="getState[setup.id_tag]"
      id="year"
      ref="year"
      type="number"
      min="0"
      max="10"
      @change="onChange($event)"
      @keyup="onChange($event, true)"
      class="noBorderInput"
    />
      <span class="spanTextAlignedWithInput">J.&nbsp;/&nbsp;</span>
    <input
        id="month"
        ref="month"
        v-bind:value="getState[setup.id_tag_monate]"
        @change="onChange($event)"
        @keyup="onChange($event, true)"
        type="number"
        min="0"
        max="11"
        class="noBorderInput"
    />
      <span class="spanTextAlignedWithInput">M.</span>
    </div>
    <span class="unit">{{ tooltip_unit }}</span>
  </div>
</template>

<script>

export default {
    name: "TextinputYearMonth",
    props: ["setup"],
    data: function () {
        return {
            // slider_value: this.setup.start,
            input_id: this.setup.id_tag,
            input_unit: this.setup.unit ? this.setup.unit : "",
            input_placeholder: this.setup.placeholder,
            factor: this.setup.multiplier ? this.setup.multiplier : 1,
            recalculate: this.setup.calculate_on_input === true,
            tooltip_unit: this.setup.unit ? " " + this.setup.unit : ""
        }
    },
    methods: {
        commitToStore: function () {
            // convert current valut for type year and type month to number of months
            // TODO
            // const months += this.$refs.month

            this.$store.commit("change_value", {
                id: this.setup.id_tag,
                value: this.$refs.year.value
            })
            this.$store.commit("change_value", {
                id: this.setup.id_tag_monate,
                value: this.$refs.month.value
            })
            // console.log("commited to store: " + this.$refs.year)
            // recalculate all values
            this.$store.commit("init_values", { calctype: "bare" })
            this.$store.commit("calc_sparrate", { calctype: "sparrate" })
            this.$store.commit("init_values", { calctype: "sMonat" })
            this.$store.commit("init_values", { calctype: "sJahr" })
            this.$store.commit("colorbar", {})
        },
        onChange: function (event, keyup = false) {
            if (keyup) {
                setTimeout(() => {
                    if (event.target.id === "year") {
                        if (event.target.value > 10) {
                            event.target.value = 10
                        } else if ((event.target.value <= 0 || isNaN(parseInt(event.target.value))) && this.getState.andere_rate_fuer_sparen_gehalt_letzes_jahr) {
                            // Bei anderer Rate für Sparen Gehalt letztes Jahr muss der Wert mindestens 1 sein
                            event.target.value = 1
                        } else if (event.target.value < 0 || isNaN(parseInt(event.target.value))) {
                            event.target.value = 0
                        }
                    } else if (event.target.id === "month") {
                        if (event.target.value > 11) {
                            event.target.value = 11
                        } else if (event.target.value < 0 || isNaN(parseInt(event.target.value))) {
                            event.target.value = 0
                        }
                    }
                    this.commitToStore()
                }, 1000)
            } else {
                this.commitToStore()
            }
        }
    },
    computed: {
        getState () {
            return this.$store.getters.getState
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.unit {
  position: absolute;
  top: 0.9rem;
  right: 0.3rem;

  @media screen and (min-width: 1408px) {
    top: 0.7rem;
  }
}
.noBorderInput {
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  font-size: 1.25rem;
  line-height: 1.5;
  width: 100%;
  height: 2.25em;
  color: #363636;
  margin: 0;
  box-sizing: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: #dbdbdb;
  }
  &::-moz-placeholder {
    color: #dbdbdb;
  }
  &:-ms-input-placeholder {
    color: #dbdbdb;
  }
  &:-moz-placeholder {
    color: #dbdbdb;
  }
}
.spanTextAlignedWithInput{
  padding-top: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #363636;
  margin: 0;
  box-sizing: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#year-month-input{
  min-width: 9.375rem;
}
</style>
