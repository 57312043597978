<template>
<ModalRechner>
<template v-slot:header>
  <h3 v-html="returnHtml(txtString.step5.left.sparzielNotPossibleTitle)"/>
</template>
<template v-slot:body>
  <p v-html="returnHtml(txtString.step5.left.sparzielNotPossible)"/>
</template>
<template v-slot:footer>
  <div style="display: flex; justify-content: center">
    <div  :style="forwardButton" class="button" @click="$emit('click-sparzielAendern')">
      <span v-html="returnHtml(txtString.step3.modal.buttonSparzielaendern)"></span>
    </div>
  </div>
</template>
</ModalRechner>
</template>

<script>
import ModalRechner from "./ModalRechner.vue"

export default {
    name: "ModalNotPossible",
    components: {
        ModalRechner
    },
    data () {
        return {
            anrede: null,
            currentComponent: null,
            // eslint-disable-next-line no-undef
            arrow: pdf_arrow_url
        }
    },
    methods: {
        closeModal () {
            this.$emit("close")
        },
    },
    computed: {
        getState () {
            return this.$store.getters.getState
        },
        forwardButton () {
            return (
                "color: " +
          this.getState.theme.color.arrow +
          " ; border-color: " +
          this.getState.theme.color.arrow
            )
        }
    }
}
</script>
