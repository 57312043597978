export const monats_zahl = {
  Januar: 1,
  Februar: 2,
  März: 3,
  April: 4,
  Mai: 5,
  Juni: 6,
  Juli: 7,
  August: 8,
  September: 9,
  Oktober: 10,
  November: 11,
  Dezember: 12,
};

export const MIN_BRUTTO_AUSZAHLUNG = 520;

export const lebenserwartung_dav = {
  63: {
    männlich: 29,
    weiblich: 33,
  },
  64: {
    männlich: 28,
    weiblich: 32,
  },
  65: {
    männlich: 26,
    weiblich: 31,
  },
  66: {
    männlich: 25,
    weiblich: 29,
  },
  67: {
    männlich: 24,
    weiblich: 29,
  },
  68: {
    männlich: 23,
    weiblich: 27,
  },
  69: {
    männlich: 21,
    weiblich: 26,
  },
  70: {
    männlich: 20,
    weiblich: 25,
  },
};

export const lebenserwartung_bund = {
  63: {
    männlich: 21,
    weiblich: 23,
  },
  64: {
    männlich: 20,
    weiblich: 23,
  },
  65: {
    männlich: 19,
    weiblich: 22,
  },
  66: {
    männlich: 18,
    weiblich: 21,
  },
  67: {
    männlich: 17,
    weiblich: 20,
  },
  68: {
    männlich: 15,
    weiblich: 19,
  },
  69: {
    männlich: 14,
    weiblich: 18,
  },
  70: {
    männlich: 13,
    weiblich: 16,
  },
};

export const lebenserwartung_unisex = {
  63: {
    männlich: 30,
    weiblich: 30,
  },
  64: {
    männlich: 30,
    weiblich: 30,
  },
  65: {
    männlich: 30,
    weiblich: 30,
  },
  66: {
    männlich: 30,
    weiblich: 30,
  },
  67: {
    männlich: 30,
    weiblich: 30,
  },
  68: {
    männlich: 30,
    weiblich: 30,
  },
  69: {
    männlich: 29,
    weiblich: 29,
  },
  70: {
    männlich: 29,
    weiblich: 29,
  },
};
