<template>
  <div class="is-relative ziel">    
    <div class="container has-text-centerd has-text-weight-semibold">
     
        <component v-bind:is="ziel_name" />
        
      <div class="has-text-centered is-size-5-tablet">{{ ziel_text }}</div>
    </div>
  </div>
</template>

<script>

import { bus } from "../main";
import { mapState } from "vuex";
export default {
  name: "Ziel",
  
  props: ["setup"],
  data: function () {
    return {
      ziel_name: this.setup.name.charAt(0).toUpperCase() + this.setup.name.slice(1),
      ziel_text: this.setup.titel,
    };
  },
  methods: {
    onChange: function (event) {
      this.$store.commit("change_value", {
        id: event.target.id,
        value: event.target.value * this.factor,
      });
    },
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
  },

  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
