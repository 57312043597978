const module2 = document.querySelector(".spin-cards");
const btnRounded = document.querySelector(".is-rounded");
const btnPrimary = document.querySelector(".spin-cards .button.intern");
const svgIcon1 = document.querySelector('.svg-icon-1');
const sub1 = document.querySelector('.sub-1');
const sub2 = document.querySelector('.sub-2');
const title = document.querySelector('.title3');
const bigContent = document.querySelector('.big-content');
const front = document.getElementsByClassName('front');
const back = document.getElementsByClassName('back');

export function spinCards() {
    if (sub1) {
        sub1.addEventListener("click", function () {
            module2.style.backgroundColor = '#004068';
            svgIcon1.style.transition = 'all .5s ease';
            svgIcon1.style.transform = 'translateX(0)';
            btnRounded.value = 'off';
            sub2.style.color = '#ffffff';
            sub1.style.color = '#ffe200';
            title.innerHTML = 'Chancen für Angestellte';
            btnPrimary.innerHTML = 'Alle Vorteile Für Mitarbeiter';
            btnPrimary.setAttribute('href', 'https://staging.pensexpert.de/vorsorgekonten/fuer-mitarbeitende/');
            btnPrimary.setAttribute('target', '_blank');
            btnPrimary.setAttribute('rel', 'noopener noreferer');
            bigContent.innerHTML = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. In ea placeat adipisci dolores. Consequuntur fuga, repellat porro alias quae commodi aliquid cumque sit dignissimos vitae vel excepturi ab, corporis placeat!";

            for (var i = 0; i < front.length; i++) {
                front[i].style.transform = 'rotateY(0deg)';
                front[i].style.transition = `all 1s ease 0.${front.length - i}s`;
            }

            for (var i = 0; i < back.length; i++) {
                back[i].style.transform = 'rotateY(180deg)';
                back[i].style.transition = `all 1s ease 0.${back.length - i}s`;
            }
        });
    }

    if (sub2) {
        sub2.addEventListener("click", function () {
            module2.style.backgroundColor = '#40454F';
            svgIcon1.style.transition = 'all .5s ease';
            svgIcon1.style.transform = 'translateX(3.6rem)';
            btnRounded.value = 'on';
            sub1.style.color = '#ffffff';
            sub2.style.color = '#ffe200';
            title.innerHTML = 'Chancen für Unternehmer';
            btnPrimary.setAttribute('href', 'https://staging.pensexpert.de/vorsorgekonten/fuer-unternehmen/');
            btnPrimary.setAttribute('target', '_blank');
            btnPrimary.setAttribute('rel', 'noopener noreferer');

            btnPrimary.innerHTML = 'Alle Vorteile Für Unternehmer';
            bigContent.innerHTML = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.';

            for (var i = 0; i < front.length; i++) {
                front[i].style.transform = 'rotateY(-180deg)';
                front[i].style.transformStyle = 'preserve-3d';
                front[i].style.transition = `all 1s ease 0.${i}s`;
            }

            for (var i = 0; i < back.length; i++) {
                back[i].style.transform = 'rotateY(0)';
                back[i].style.transformStyle = 'preserve-3d';
                back[i].style.transition = `all 1s ease 0.${i}s`;

            }
        });
    }

    if (btnRounded) {
        btnRounded.addEventListener("mousedown", function () {
            if (btnRounded.value == 'off') {
                module2.style.backgroundColor = '#40454F';
                svgIcon1.style.transition = 'all .5s ease';
                svgIcon1.style.transform = 'translateX(3.6rem)';
                btnRounded.value = 'on';
                sub1.style.color = '#ffffff';
                sub2.style.color = '#ffe200';
                title.innerHTML = 'Chancen für Unternehmer';
                btnPrimary.innerHTML = 'Alle Vorteile Für Unternehmer';
                btnPrimary.setAttribute('href', 'https://staging.pensexpert.de/vorsorgekonten/fuer-unternehmen/');
                btnPrimary.setAttribute('target', '_blank');
                btnPrimary.setAttribute('rel', 'noopener noreferer');
                bigContent.innerHTML = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.';

                for (var i = 0; i < front.length; i++) {
                    front[i].style.transform = 'rotateY(-180deg)';
                    front[i].style.transformStyle = 'preserve-3d';
                    front[i].style.transition = `all 1s ease 0.${i}s`;
                }

                for (var i = 0; i < back.length; i++) {
                    back[i].style.transform = 'rotateY(0)';
                    back[i].style.transformStyle = 'preserve-3d';
                    back[i].style.transition = `all 1s ease 0.${i}s`;

                }

            } else {
                module2.style.backgroundColor = '#004068';
                svgIcon1.style.transition = 'all .5s ease';
                svgIcon1.style.transform = 'translateX(0)';
                btnRounded.value = 'off';
                sub2.style.color = '#ffffff';
                sub1.style.color = '#ffe200';
                title.innerHTML = 'Chancen für Angestellte';
                btnPrimary.innerHTML = 'Alle Vorteile Für Mitarbeiter';
                btnPrimary.setAttribute('href', 'https://staging.pensexpert.de/vorsorgekonten/fuer-mitarbeitende/');
                btnPrimary.setAttribute('target', '_blank');
                btnPrimary.setAttribute('rel', 'noopener noreferer');
                bigContent.innerHTML = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. In ea placeat adipisci dolores. Consequuntur fuga, repellat porro alias quae commodi aliquid cumque sit dignissimos vitae vel excepturi ab, corporis placeat!";

                for (var i = 0; i < front.length; i++) {
                    front[i].style.transform = 'rotateY(0deg)';
                    front[i].style.transition = `all 1s ease 0.${front.length - i}s`;
                }

                for (var i = 0; i < back.length; i++) {
                    back[i].style.transform = 'rotateY(180deg)';
                    back[i].style.transition = `all 1s ease 0.${back.length - i}s`;
                }
            }
        });
    }

}