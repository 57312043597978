<template>
  <svg
    class="auszeit"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    aria-labelledby="iconName"
    style="enable-background:new 0 0 80 80;"
    xml:space="preserve"
    role="presentation"
    x="0px"
    y="0px"
  >
    <title id="iconName" lang="en">auszeit</title>

    <path :style="style1" class="st1" d="M58.986492,51.599934c3.671326-3.550598,5.953922-8.528259,5.953922-14.039001
		c0-10.785341-8.743229-19.528566-19.528568-19.528566c-5.683289,0-10.783447,2.442627-14.352171,6.317627
		c-4.834045,3.114197-8.043274,8.532595-8.043274,14.71039c0,5.275208,2.334167,10.00531,6.02594,13.213806"/>
	<path :style="style3" class="st23" d="M28.424095,52.021923c-3.345667-3.285202-5.420885-7.859863-5.420885-12.919216
		c0-9.999205,8.105957-18.105162,18.105162-18.105162s18.105156,8.105957,18.105156,18.105162
		c0,4.939526-1.978081,9.417046-5.185314,12.683632"/>
  <line :style="style2" class="st2" x1="17.031307" y1="52.012325" x2="60.087757" y2="52.012325"/>
	<line :style="style2" class="st2" x1="32.065414" y1="56.996956" x2="65.072342" y2="56.996956"/>
	<line :style="style2" class="st2" x1="24.065285" y1="62.023151" x2="37.02742" y2="62.023151"/>
		<path :style="style3" class="st23" d="M20.908655,25.764294c-0.807159-0.347765-1.820555-0.474659-2.793491-0.127542
		c-1.42631,0.508867-1.798416,1.368927-2.247666,2.268303c-0.863329-0.515139-1.873971-1.058651-3.443368-0.602339
		c-0.959445,0.278963-1.738933,0.847023-2.234415,1.542736"/>

<slot />
  </svg>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Auszeit",
  methods: {},
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style1() {
      return "fill:" + this.getState.theme.color.arrow+"; opacity: 0.5";
    },
     style2() {
      return "fill:none;stroke:" + this.getState.theme.color.arrow+";stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
    },
     style3() {
       var color=this.getState.theme.color.arrow
       var halfColor= color.slice(0,1)+"51"+color.slice(1)
     
      return "fill:none;stroke:" + this.getState.theme.color.arrow+";stroke-width:1.960064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
