import Swiper from 'swiper/bundle';

import 'swiper/swiper-bundle.css';

var sliderLogos;

function playLogosSlider() {
    if (document.getElementsByClassName('slider-logos-container').length) {

        const swiperOptions = {
            loop: true,
            freeMode: true,
            spaceBetween: 30,
            slidesPerView: 2,
            autoplay: {
                delay: 1,
                disableOnInteraction: false
            },
            speed: 4000,
            freeModeMomentum: false,
            allowTouchMove: false,
            breakpoints: {
                500: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 5,
                },
                1024: {
                    slidesPerView: 6,
                },
            },
        };

        sliderLogos = new Swiper('.slider-logos-container', swiperOptions);

    }
}

function playCardsSlider() {

    if (document.getElementsByClassName('slider-cards-container').length) {
        [...document.getElementsByClassName('slider-cards-container')].forEach((slider, i) => {

            let wrapper = slider.getElementsByClassName('swiper-container')[0];
            let pagination = slider.getElementsByClassName('swiper-pagination')[0];
            let arrowLeft = slider.getElementsByClassName('swiper-button-prev')[0];
            let arrowRight = slider.getElementsByClassName('swiper-button-next')[0];

            if (wrapper) {
                wrapper.classList.add(`cards-slider${i}`);
            } if (pagination) {
                pagination.classList.add(`swiper-pagination${i}`);
            } if (arrowLeft) {
                arrowLeft.classList.add(`cards-slider-left${i}`);
            } if (arrowRight) {
                arrowRight.classList.add(`cards-slider-right${i}`);
            }

            new Swiper(`.cards-slider${i}`, {
                spaceBetween: 30,
                slidesPerView: 1,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                    1216: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: `.cards-slider-right${i}`,
                    prevEl: `.cards-slider-left${i}`,
                },
                pagination: {
                    el: `.swiper-pagination${i}`,
                    clickable: true,
                },
            });
        })
    }
}

function zitateSlider() {

    if (document.getElementsByClassName('zitat-slider').length) {
        [...document.getElementsByClassName('zitat-slider')].forEach((slider, i) => {

            let wrapper = slider.getElementsByClassName('swiper-container')[0];
            let pagination = slider.getElementsByClassName('swiper-pagination')[0];
            let arrowLeft = slider.getElementsByClassName('swiper-button-prev')[0];
            let arrowRight = slider.getElementsByClassName('swiper-button-next')[0];

            if (wrapper) {
                wrapper.classList.add(`zitat-slider${i}`);
            } if (pagination) {
                pagination.classList.add(`swiper-pagination${i}`);
            } if (arrowLeft) {
                arrowLeft.classList.add(`zitat-slider-left${i}`);
            } if (arrowRight) {
                arrowRight.classList.add(`zitat-slider-right${i}`);
            }

            new Swiper(`.zitat-slider${i}`, {
                slidesPerView: "auto",
                spaceBetween: 30,
                breakpoints: {
                    768: {
                        slidesPerView: "auto",
                        centeredSlides: true,
                    },
                },
                navigation: {
                    nextEl: `.zitat-slider-right${i}`,
                    prevEl: `.zitat-slider-left${i}`,
                },
                pagination: {
                    el: `.swiper-pagination${i}`,
                    clickable: true,
                },
            });
        })
    }
}

export function initializeSliders() {
    playLogosSlider();
    playCardsSlider();
    zitateSlider();

    window.addEventListener('resize', function () {
        if (sliderLogos) {
            sliderLogos.autoplay.start();
        }
    });
}