<template>
  <svg
    class="pfeil"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    aria-labelledby="iconName"
    style="enable-background:new 0 0 80 80;"
    xml:space="preserve"
    role="presentation"
    x="0px"
    y="0px"
  >
    <title id="iconName" lang="en">icon</title>
    <g id="Ebene_1">
	<path :style="style1" class="st1" d="M50.994526,47.059608v-8.150211c0-2.74342,4.956051-4.28669,7.699467-4.28669
		c0,0-1.628201-16.557995-17.634262-16.723576c-16.805466-0.173849-18.213795,16.889156-18.213795,16.889156
		c2.743418,0,7.175131,1.377689,7.175131,4.121109v8.150211l-0.220776,0.248371h21.41501"/>
	<path :style="style2" class="st2" d="M51.436073,52.670921H30.021065c-1.463156,0-2.649277-1.186123-2.649277-2.649277v-0.000008
		c0-1.463154,1.186121-2.649277,2.649277-2.649277h21.415009c1.463158,0,2.649277,1.186123,2.649277,2.649277v0.000008
		C54.08535,51.484798,52.899231,52.670921,51.436073,52.670921z"/>
	<path :style="style2" class="st2" d="M30.24184,47.123993V39.97646c0-2.74342-2.22398-4.9674-4.967398-4.9674s-4.9674,2.22398-4.9674,4.9674
		c0,1.84164,1.002209,3.449207,2.490982,4.307053L22.73555,55.174671c0,2.153179,1.745495,3.898674,3.898674,3.898674h28.18869
		c2.153179,0,3.898674-1.745495,3.898674-3.898674l-0.062473-10.891159c1.488773-0.857845,2.490982-2.465412,2.490982-4.307053
		c0-2.74342-2.22398-4.9674-4.9674-4.9674s-4.9674,2.22398-4.9674,4.9674v7.147533"/>
	<path :style="style2" class="st2" d="M25.274443,35.00906c0,0,3.642757-11.369823,15.785288-11.369823S56.182697,35.00906,56.182697,35.00906"/>
	<path :style="style2" class="st2" d="M30.763582,59.018158v2.256992c0,1.440891-1.147894,2.608959-2.563892,2.608959h-0.000006
		c-1.415998,0-2.56389-1.168068-2.56389-2.608959v-2.256992"/>
	<path :style="style2" class="st2" d="M55.821346,59.294117v1.981033c0,1.440891-1.147892,2.608959-2.563892,2.608959H53.25745
		c-1.416,0-2.563892-1.168068-2.563892-2.608959v-1.81546"/>
	<g>
		<line :style="style3" class="st23" x1="40.801662" y1="16.102154" x2="40.801662" y2="32.692642"/>
		<polyline :style="style3" class="st23" points="37.799171,30.35235 40.728569,33.281746 43.657967,30.35235 		"/>
	</g>
</g>
    <slot />
  </svg>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Rente",
  methods: {},
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style1() {
      return "fill:" + this.getState.theme.color.arrow+"; opacity: 0.5";
    },
     style2() {
      return "fill:none;stroke:" + this.getState.theme.color.arrow+";stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
    },
     style3() {
       var color=this.getState.theme.color.arrow
       var halfColor= color.slice(0,1)+"51"+color.slice(1)
      
      return "fill:none;stroke:" + this.getState.theme.color.arrow+";stroke-width:1.960064;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
