<template>
  <div class="contactinfo">
    <div class="columns is-mobile">
      <div class="column is-1">
        <img class="pfeil" :src="arrow" alt="" />
      </div>
      <div class="column is-8">
        <p
          class="is-uppercase"
          v-html="returnHtml(txtString.pdf.footer.p1) || ''"
        >
          <!-- footer p1 -->
        </p>
        <p v-html="returnHtml(txtString.pdf.footer.p2) || ''">
          <!-- footer p2 -->
        </p>
        <p
          class="is-size-7 noted"
          v-html="returnHtml(txtString.pdf.footer.note1) || ''"
        >
          <!-- note1 -->
        </p>
        <p
          class="is-size-7"
          v-html="returnHtml(txtString.pdf.footer.note2) || ''"
        >
          <!-- note2 -->
        </p>
      </div>
      <div class="column is-2">
        <p
          class="date is-size-7"
          v-html="returnHtml(txtString.pdf.footer.created) || ''"
        >
          <!-- created -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "../svg/Arrow.vue";
import { mapState } from "vuex";
export default {
  name: "PdfDownloadFooter",
  data: () => {
    return {
      arrow: pdf_arrow_url,
    };
  },
  components: {
    Arrow,
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
