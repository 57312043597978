import lottie from 'lottie-web';
import * as animationLeistungenDesktop from './anim_leistungen.json';
import * as animationLeistungenMobile from './anim_leistungen_mobile.json';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


var containerLeistungenDesktop = document.getElementById('anim_leistungen_desktop');
var containerLeistungenMobile = document.getElementById('anim_leistungen_mobile');
var getUrl = window.location;
var baseUrl = getUrl.protocol + "//" + getUrl.host;
var apiData;
const apiUrl = `${baseUrl}/wp-json/wp/v2/pages?per_page=100`;


export function initializeLottieAnimations() {

    fetchDataAnimLeistungen();

};


var lottieAnimLeistungenDesktop, lottieAnimLeistungenMobile;
var button_Rechtsberatung, button_RechtsberatungMobile, button_OnlineKonten, button_OnlineKontenMobile, button_Kapitalanlage, button_KapitalanlageMobile, button_Kommunikation, button_KommunikationMobile, button_Insolvenzsicherung, button_InsolvenzsicherungMobile, allButtons, allButtonsMobile;
var menuOptionsDesktop = [
    { btn: 'beratung-und-konzept', playSegment: [100, 115], reverseSegment: [125, 140] },
    { btn: 'penstec', playSegment: [150, 165], reverseSegment: [175, 190], },
    { btn: 'kapitalanlage', playSegment: [200, 215], reverseSegment: [225, 245] },
    { btn: 'support', playSegment: [250, 265], reverseSegment: [275, 290], },
    { btn: 'insolvenzsicherung', playSegment: [300, 315], reverseSegment: [325, 345], }
];
var menuOptionsMobile = [
    { btn: 'beratung-und-konzept', playSegment: [200, 215], reverseSegment: [225, 245] },
    { btn: 'penstec', playSegment: [250, 265], reverseSegment: [275, 295], },
    { btn: 'kapitalanlage', playSegment: [300, 315], reverseSegment: [325, 345] },
    { btn: 'support', playSegment: [350, 365], reverseSegment: [375, 390], },
    { btn: 'insolvenzsicherung', playSegment: [400, 415], reverseSegment: [425, 445], }
];

function fetchDataAnimLeistungen() {
    // console.log('fetching from: ', apiUrl)
    fetch(apiUrl)
        .then(function (response) {
            return response.json();
        }).then(function (data) {
            apiData = data;
        }).then(function () {
            runAnimation();
        }).catch(function (error) {
            console.log(error);
        });
};


function runAnimation() {
    showCorrectAnimation();
    window.addEventListener('resize', function () {
        showCorrectAnimation();
    })
};


function showCorrectAnimation() {

    if (window.innerWidth > 769) {
        if (lottieAnimLeistungenMobile && lottieAnimLeistungenMobile !== 'undefined') {
            lottieAnimLeistungenMobile.destroy();
        }
        runAnimationLeistungenDesktop();
    } else {
        if (lottieAnimLeistungenDesktop && lottieAnimLeistungenDesktop !== 'undefined') {
            lottieAnimLeistungenDesktop.destroy();
        }
        runAnimationLeistungenMobile();
    }
};


function runAnimationLeistungenDesktop() {

    if (!lottieAnimLeistungenDesktop || lottieAnimLeistungenDesktop._cbs === null) {

        lottieAnimLeistungenDesktop = lottie.loadAnimation({
            container: containerLeistungenDesktop,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animationLeistungenDesktop.default,
        });

        lottieAnimLeistungenDesktop.addEventListener('DOMLoaded', () => {

            ScrollTrigger.create({
                trigger: containerLeistungenDesktop,
                onEnter: playLottieAnimLeistungenDesktop,
            });

            function playLottieAnimLeistungenDesktop() {
                lottieAnimLeistungenDesktop.playSegments([0, 90], true);
                button_Rechtsberatung = document.getElementById('beratung-und-konzept');
                button_OnlineKonten = document.getElementById('penstec');
                button_Kapitalanlage = document.getElementById('kapitalanlage');
                button_Kommunikation = document.getElementById('support');
                button_Insolvenzsicherung = document.getElementById('insolvenzsicherung');
                allButtons = new Array(button_Rechtsberatung, button_OnlineKonten, button_Kapitalanlage, button_Kommunikation, button_Insolvenzsicherung);
                manipulateAnimLeistungenDesktop();
            }

            function manipulateAnimLeistungenDesktop() {
                // console.log('DATA: ', apiData);

                allButtons.map(button => {
                    if (button) {

                        var hoveredOption = menuOptionsDesktop.filter(option => option.btn === button.id)[0];

                        button.addEventListener('mouseover', function () {
                            lottieAnimLeistungenDesktop.playSegments(hoveredOption.playSegment, true);
                        })

                        button.addEventListener('mouseout', function () {
                            lottieAnimLeistungenDesktop.playSegments(hoveredOption.reverseSegment, true);
                        })

                        button.addEventListener('click', function () {
                            var selectedData = apiData.filter(data => data.slug === button.id)[0];
                            location.href = selectedData.link;
                        })
                    }
                });
            };
        });
    }
};


function runAnimationLeistungenMobile() {

    if (!lottieAnimLeistungenMobile || lottieAnimLeistungenMobile._cbs === null) {

        lottieAnimLeistungenMobile = lottie.loadAnimation({
            container: containerLeistungenMobile,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animationLeistungenMobile.default,
        });

        lottieAnimLeistungenMobile.addEventListener('DOMLoaded', () => {

            ScrollTrigger.create({
                trigger: containerLeistungenMobile,
                onEnter: playLottieAnimLeistungenMobile,
            });

            function playLottieAnimLeistungenMobile() {
                lottieAnimLeistungenMobile.playSegments([0, 160], true);
                button_RechtsberatungMobile = document.getElementById('beratung-und-konzept2');
                button_OnlineKontenMobile = document.getElementById('penstec2');
                button_KapitalanlageMobile = document.getElementById('kapitalanlage2');
                button_KommunikationMobile = document.getElementById('support2');
                button_InsolvenzsicherungMobile = document.getElementById('insolvenzsicherung2');
                allButtonsMobile = new Array(button_RechtsberatungMobile, button_OnlineKontenMobile, button_KapitalanlageMobile, button_KommunikationMobile, button_InsolvenzsicherungMobile);
                manipulateAnimLeistungenMobile();
            }
        });

        function manipulateAnimLeistungenMobile() {
            // console.log('DATA: ', apiData);

            allButtonsMobile.map(button => {
                if (button) {

                    var hoveredOptionMobile = menuOptionsMobile.filter(option => option.btn + '2' === button.id)[0];

                    button.addEventListener('mouseover', function () {
                        // console.log('hoveredOptionMobile: ', hoveredOptionMobile);
                        lottieAnimLeistungenMobile.playSegments(hoveredOptionMobile.playSegment, true);
                    })

                    button.addEventListener('mouseout', function () {
                        lottieAnimLeistungenMobile.playSegments(hoveredOptionMobile.reverseSegment, true);
                    })

                    button.addEventListener('click', function () {
                        var selectedData = apiData.filter(data => data.slug + '2' === button.id)[0];
                        location.href = selectedData.link;
                    })
                }
            });
        };
    }
};