import bulmaCollapsible from "@creativebulma/bulma-collapsible";

var navbarIsOpened = false;

export function Navigation() {

    var burger = document.querySelector(".burger");
    var brand = document.querySelector(".navbar-brand");
    var nav = document.querySelector("#primary-menu");

    window.addEventListener("resize", function () {
        if (window.innerWidth > 1024) {
            navbarIsOpened = false;
        }
        if (!navbarIsOpened) {
            burger.classList.remove("is-active");
            nav.classList.remove("is-active");
            brand.classList.remove("is-open");
        }
        // console.log(navbarIsOpened);
    });


    if (burger !== null) {
        burger.addEventListener("click", function () {
            burger.classList.toggle("is-active");
            nav.classList.toggle("is-active");
            brand.classList.toggle("is-open");
            navbarIsOpened = !navbarIsOpened;
        });
        bulmaCollapsible.attach(".is-collapsible-menu");


        let navbar = document.querySelector(".navbar");

        function Sticky() {
            document.documentElement.scrollTop > 0
                ? navbar.classList.add("sticky")
                : navbar.classList.remove("sticky");
        }

        window.addEventListener("load", Sticky);
        window.addEventListener("scroll", Sticky);
    }

    // navbar open with mouseover
    const navigation = document.getElementById('navbar');
    const leistungen = [...document.getElementsByClassName('Leistungen')][0];

    navigation.addEventListener('mouseover', function () {

        if (navigation && navigation.style.maxHeight !== '6.25rem') {
            navigation.style.maxHeight = '6.25rem';
        } else {
            if (navigation.classList.contains('sticky')) {
                navigation.classList.remove('sticky');
                navigation.classList.add('had-sticky');
                navigation.style.maxHeight = navigation.scrollHeight + "px";
                // leistungen.classList.add('opened');
            } else {
                navigation.style.maxHeight = navigation.scrollHeight + "px";
                // leistungen.classList.add('opened');
            }
        }
    })

    navigation.addEventListener('mouseout', function () {
        navigation.style.maxHeight = '6.25rem';
        if (navigation.classList.contains('had-sticky')) {
            navigation.classList.add('sticky');
            navigation.classList.remove('had-sticky');
        }
        // leistungen.classList.remove('opened');
    })

}
