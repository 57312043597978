export function archiveWiki() {
  const archive = document.querySelector(".wiki-archive");
  const singleArchive = document.querySelector(".single-archive");
  const filters = [...document.querySelectorAll(".filter")];
  const alphabetArr = [];
  const posts = [...document.querySelectorAll(".post")];
  const stickyElement = document.querySelector(".filter-level.level-1");

  if (archive && !singleArchive) {
  const alleLabel = document.querySelector(".alle-label").innerHTML;
  const link = `<span class="filter"><a href="/wiki" class="filter-link filter-link-alle" title="${alleLabel}">${alleLabel}</a></span>`;
  stickyElement.insertAdjacentHTML("afterbegin", link);
  
    document.addEventListener("scroll", function () {
      const stickyElementPositioner = document.querySelector(
        ".encyclopedia-prefix-filters"
      );
      const elementTarget = stickyElementPositioner.getBoundingClientRect().top;
      if (elementTarget <= 58) {
        stickyElementFixed();
      } else {
        stickyElementStatic();
      }
    });
  }

  posts.forEach((post) => {
	const alphabet = post
		.getElementsByClassName('entry-title')[0]
		.innerHTML.charAt(0);
	post.classList.add(alphabet);
	if (!alphabetArr.includes(alphabet)) {
		alphabetArr.push(alphabet);
	}
});
filters.forEach((filter) => {
	const alphabetReplaced = filter
		.getElementsByTagName('a')[0]
		.innerHTML.replace(/\W/g, '');
	if (alphabetArr.includes(alphabetReplaced)) {
		filter.classList.add('current-filter');
	}
});
  function stickyElementFixed() {
    stickyElement.classList.add("sticky-element-sticky");
  }

  function stickyElementStatic() {
    stickyElement.classList.remove("sticky-element-sticky");
  }
}
