<template>
  <!--    modal für 70% - 130% Regel-->
  <ModalRechner>
    <template v-slot:header>
      <h3
        style="text-align: center"
        v-if="getState.over130"
        v-html="txtString.step3.modal.headlineOver130"
      ></h3>
      <h3
        style="text-align: center"
        v-if="getState.under70"
        v-html="txtString.step3.modal.headlineUnder70"
      ></h3>
    </template>
    <template v-slot:body>
      <p
        style="text-align: center"
        v-if="getState.over130"
        v-html="returnHtml(txtString.step3.modal.textOver130)"
      />
      <p
        v-if="getState.under70"
        v-html="returnHtml(txtString.step3.modal.textUnder70)"
      />
      <div v-if="getState.under70" style="font-size: 1rem !important;">
        <table class="table-modal">
          <tr>
            <td v-html="returnHtml(txtString.step3.modal.table.label1)" />
            <td><strong>{{ addCommas(Math.round(getState.letztes_gehalt)) }} €</strong></td>
          </tr>
          <tr>
            <td v-html="returnHtml(txtString.step3.modal.table.label2)"/>
            <td><strong> {{ addCommas(Math.round(getState.zielgehalt_brutto_with_gehaltssteigerung)) }} €</strong></td>
          </tr>
        </table>
        <div style="margin-top: 20px; margin-bottom: 20px">
          <div style="text-align: left"><strong v-html="returnHtml(txtString.step3.modal.table.label3)"/></div>
          <div style="text-align: left"><strong v-html="returnHtml(txtString.step3.modal.table.label4)"/></div>
        </div>
        <table class="table-modal">
          <tr>
            <td><img class="pfeil" :src="arrow" alt="" /> <span v-html="returnHtml(txtString.step3.modal.table.label5)"/> </td>
            <td><strong>{{ addCommas(Math.round(getState.sparen_gehalt_last_year_min)) }} €</strong></td>
          </tr>
          <tr>
            <td><img class="pfeil" :src="arrow" alt="" /> <span v-html="returnHtml(txtString.step3.modal.table.label6)" /></td>
            <td><strong> {{ addCommas(Math.round((getState.letztes_gehalt - getState.sparen_gehalt_last_year_min))) }} €</strong></td>
          </tr>
        </table>
      </div>
      <!-- <p v-if="getState.under70" style="text-align: center" v-html="returnHtml(txtString.step3.modal.textUnder70_2)"/> -->

      <div v-if="getState.over130">
        <div class="sample-wrapper">
          <p>
            <span>
              <strong
                  v-html="returnHtml(txtString.step3.modal.over130Labels.label1)"
              ></strong>
            </span>
            <br />
            <span>{{ addCommas(Math.round(getState.zielgehalt_brutto_with_gehaltssteigerung)) }} € Bruttogehalt/Monat</span
            >
          </p>
          <p>
            <span>
              <strong
                v-html="returnHtml(txtString.step3.modal.over130Labels.label2)"
              ></strong>
            </span>
            <br />
            <span
              >{{ addCommas(Math.round(getState.letztes_gehalt)) }} €
              Bruttogehalt/Monat</span
            >
          </p>
          <p>
            <span>
              <strong
                v-html="returnHtml(txtString.step3.modal.over130Labels.label3)"
              ></strong>
            </span>
            <br />
            <span
              v-html="returnHtml(txtString.step3.modal.over130Labels.label4)"
            ></span>
            <br />
            <span
              >{{ addCommas(Math.round(getState.letztes_gehalt * 1.3)) }} €
              Bruttogehalt/Monat</span
            >
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div
          v-if="getState.over130"
          style="display: flex; justify-content: center"
      >
        <div
            :style="forwardButton"
            class="button"
            @click="$emit('click-sparzielAendern')"
        >
          <span
              v-html="returnHtml(txtString.step3.modal.buttonSparzielaendern)"
          ></span>
        </div>
      </div>
      <div v-if="getState.under70" class="buttons-bottom">
        <div
            class="button back"
            style="background-color: #f0f0f0; border: none"
            @click="$emit('click-sparzielAendern')"
        >
          <span
              v-html="returnHtml(txtString.step3.modal.buttonSparzielaendern)"
          ></span>
        </div>
        <div
            :style="forwardButton"
            class="button"
            @click="$emit('click-zumErgebnis')"
        >
          <span
              v-html="returnHtml(txtString.step3.modal.buttonZumErgebnis)"
          ></span>
        </div>
      </div>
    </template>
  </ModalRechner>
</template>

<script>
import ModalRechner from "./ModalRechner.vue"

export default {
    name: "ModalRechnerOver130Under70",
    components: {
        ModalRechner
    },
    data () {
        return {
            anrede: null,
            currentComponent: null,
            // eslint-disable-next-line no-undef
            arrow: pdf_arrow_url
        }
    },

    methods: {
        closeModal () {
            this.$emit("close")
        },
        addCommas: function (nStr) {
            nStr += ""
            const x = nStr.split(".")
            let x1 = x[0]
            const x2 = x.length > 1 ? "." + x[1] : ""
            const rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, "$1" + "." + "$2")
            }
            return x1 + x2
        }
    },
    computed: {
        getState () {
            return this.$store.getters.getState
        },
        forwardButton () {
            return (
                "color: " +
          this.getState.theme.color.arrow +
          " ; border-color: " +
          this.getState.theme.color.arrow
            )
        }
    }
}
</script>

<style scoped lang="scss">
.buttons-bottom {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  margin-top: 30px;
}

.table-modal {
  width: 100%;
  margin-top: 20px;
}

.sample-wrapper {
  margin-top: 25px;
  p {
    margin-bottom: 25px;
  }
}

.pfeil {
  width: 40px;
  margin-right: 10px;
}

/* Selects the second li element in a list */
tr td:nth-child(1) {
  text-align: left;
}

/* Selects the second li element in a list */
tr td:nth-child(2) {
  text-align: end;
  min-width: 100px;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 90%;
  margin-bottom: 15px;
}

.tooltip .tooltiptext {
  width: 120px;
  background-color: #f7f7f7;
  color: #1c1c1c;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f7f7f7 transparent transparent transparent;
}
</style>
