import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function heroTextMedia() {
  const mediaTextSections = [...document.getElementsByClassName("split-right")];
  const textMediaSections = [...document.getElementsByClassName("split-left")];

  if (mediaTextSections.length) {
    mediaTextSections.map((section) => {
      const media = section.getElementsByClassName("has-media-bg")[0];
      const subline = section.getElementsByClassName("subline-wrapper")[0];

      if (media) {
        gsap.from(media, {
          scrollTrigger: {
            trigger: media,
            start: "top bottom-=150",
            end: "+=200",
          },
          x: "-25%",
          opacity: 0,
          duration: 1.5,
        });
      }
      if (subline) {
        gsap.from(subline, {
          scrollTrigger: {
            trigger: subline,
            start: "top bottom-=150",
            end: "+=200",
          },
          x: "25%",
          opacity: 0,
          duration: 1.5,
        });
      }
    });
  }
  if (textMediaSections.length) {
    textMediaSections.map((section) => {
      const media = section.getElementsByClassName("has-media-bg")[0];
      const subline = section.getElementsByClassName("subline-wrapper")[0];

      if (media) {
        gsap.from(media, {
          scrollTrigger: {
            trigger: media,
            start: "top bottom-=150",
            end: "+=200",
          },
          x: "25%",
          opacity: 0,
          duration: 1.5,
        });
      }
      if (subline) {
        gsap.from(subline, {
          scrollTrigger: {
            trigger: subline,
            start: "top bottom-=150",
            end: "+=200",
          },
          x: "-25%",
          opacity: 0,
          duration: 1.5,
        });
      }
    });
  }
}

export function textMedia() {
  const mediaTextSections = [...document.getElementsByClassName("image-text")];

  if (mediaTextSections.length) {
    mediaTextSections.map((section) => {
      const imgLeft = section.getElementsByClassName("image-left")[0];
      const imgRight = section.getElementsByClassName("image-right")[0];
      if (imgLeft) {
        gsap.from(imgLeft, {
          scrollTrigger: {
            trigger: imgLeft,
            start: "top bottom-=150",
            end: "+=200",
          },
          x: "-25%",
          opacity: 0,
          duration: 1.5,
        });
      }
      if (imgRight) {
        gsap.from(imgRight, {
          scrollTrigger: {
            trigger: imgRight,
            start: "top bottom-=150",
            end: "+=200",
          },
          x: "25%",
          opacity: 0,
          duration: 3,
        });
      }
    });
  }
}
export function statment() {
  const statementSections = [
    ...document.getElementsByClassName("section-statment"),
  ];

  if (statementSections.length) {
    console.log("statmentsLength: ", statementSections.length);

    statementSections.map((section) => {
      console.log(section);
      const imgLeft = section.querySelector("image-left .image");
      const imageBox = section.querySelector(".image-box");
      const imgRight = section.querySelector(".image-right .image");
      const circleAnimation = section.querySelector(".circle-animation");
      console.log(imgLeft, imgRight, circleAnimation);
      if (imageBox && circleAnimation) {
        gsap.from(circleAnimation, {
          scrollTrigger: {
            trigger: imageBox,
            start: "top 85%-=150",
            end: "+=200",
            scrub: 1,
          },
          scale: 0.5,
          opacity: 0,
          duration: 1.5,
        });
      }
      if (imgLeft && imageBox) {
        gsap.from(imgLeft, {
          scrollTrigger: {
            trigger: imageBox,
            start: "top 60%-=150",
            end: "+=100",
            scrub: 1,
          },
          x: "-35%",
          opacity: 0,
          duration: 1.5,
        });
      }
      if (imgRight && imageBox) {
        gsap.from(imgRight, {
          scrollTrigger: {
            trigger: imageBox,
            start: "top 60%-=150",
            end: "+=100",
            scrub: 1,
          },
          x: "35%",
          opacity: 0,
          duration: 1.5,
        });
      }
    });
  }
}
export function sectionFadeBottomToTop() {
  const animatedSections = [
    ...document.getElementsByClassName("fade-bottom-animation"),
  ];
  const imageSections = [...document.getElementsByClassName("image-module")];
  const sections = [...document.getElementsByClassName("contact-form")];

  function fading(sec) {
    if (sec.length) {
      sec.map((section) => {
        const image = section.getElementsByTagName("img")[0];

        if (image) {
          gsap.from(image, {
            scrollTrigger: {
              trigger: section,
              start: "top bottom-=100",
              end: "+=200",
            },
            y: "25%",
            opacity: 0,
            duration: 1.5,
          });
        } else {
          gsap.from(section, {
            scrollTrigger: {
              trigger: section,
              start: "top bottom-=100",
              end: "+=200",
            },
            y: "25%",
            opacity: 0,
            duration: 1,
          });
        }
      });
    }
  }

  fading(animatedSections);
  fading(imageSections);
  fading(sections);
}

export function arrowAnimation() {
  const sections = [...document.getElementsByClassName("column-arrow")];

  if (sections.length) {
    sections.map((sect) => {
      const arrow = [...sect.getElementsByTagName("svg")][0];
      if (arrow) {
        gsap.from(arrow, {
          scrollTrigger: {
            trigger: arrow,
            start: "top bottom-=100",
            end: "+=300",
            scrub: 1,
          },
          x: "-45%",
          duration: 3,
        });
      }
    });
  }
}

export function timelineAnimation() {
  const sliderSections = [...document.getElementsByClassName("cards-list")];
  if (sliderSections.length) {
    sliderSections.map((section) => {
      const cards = [...section.getElementsByClassName("card")];

      if (cards) {
        cards.map((card) => card.classList.add("timeline-single-anim"));
      }

      gsap.defaults({ ease: "power4", duration: 1.5 });
      gsap.set(".timeline-single-anim", { y: "20%", opacity: 0 });

      ScrollTrigger.batch(".timeline-single-anim", {
        onEnter: (batch) =>
          gsap.to(batch, { opacity: 1, y: 0, stagger: 0.25, overwrite: true }),
      });
    });
  }
}

export function timelineAnimationLinks() {
  const sliderSectionsTextMitLinks = [
    ...document.getElementsByClassName("text-mit-links"),
  ];

  if (sliderSectionsTextMitLinks.length) {
    sliderSectionsTextMitLinks.map((section) => {
      const links = [...section.getElementsByClassName("link-item")];

      if (links) {
        links.map((link) => link.classList.add("timeline-single-anim-link"));
      }

      gsap.defaults({ ease: "power4", duration: 1.5 });
      gsap.set(".timeline-single-anim-link", { x: "25%", opacity: 0 });

      ScrollTrigger.batch(".timeline-single-anim-link", {
        onEnter: (batch) =>
          gsap.to(batch, { opacity: 1, x: 0, stagger: 0.35, overwrite: true }),
      });
    });
  }
}

export function timelineAnimationWithArrows() {
  const grid2sections = [...document.getElementsByClassName("grid grid-2")];
  const grid3sections = [...document.getElementsByClassName("grid grid-3")];

  function playTimeline(sec) {
    if (sec.length) {
      sec.map((section) => {
        const cards = [
          ...section.getElementsByClassName("column is-12-mobile"),
        ];

        if (cards) {
          cards.map((card) => card.classList.add("timeline-single"));
        }

        gsap.defaults({ ease: "power4", duration: 2 });
        gsap.set(".timeline-single", { x: "25%", opacity: 0 });

        ScrollTrigger.batch(".timeline-single", {
          onEnter: (batch) =>
            gsap.to(batch, {
              opacity: 1,
              x: 0,
              stagger: 0.25,
              overwrite: true,
            }),
        });
      });
    }
  }

  playTimeline(grid2sections);
  playTimeline(grid3sections);
}

export function timelineAnimationCardsColor() {
  const sliderSections = [
    ...document.getElementsByClassName("grid with-arrow"),
  ];

  if (sliderSections.length) {
    sliderSections.map((section) => {
      const cards = [...section.getElementsByClassName("column is-12-mobile")];

      if (cards) {
        cards.map((card) => card.classList.add("timeline-single-anim-arrow"));
      }

      gsap.defaults({ ease: "power4", duration: 2 });
      gsap.set(".timeline-single-anim-arrow", { x: "-25%", opacity: 0 });

      ScrollTrigger.batch(".timeline-single-anim-arrow", {
        onEnter: (batch) =>
          gsap.to(batch, { opacity: 1, x: 0, stagger: 0.25, overwrite: true }),
      });
    });
  }
}

export function timelineAnimationButtons() {
  const buttons = [
    ...document.querySelectorAll(".button.is-outlined:not(.move1):not(.move2)"),
  ];

  if (buttons.length) {
    buttons.map((button) => {
      button.classList.add("timeline-single-anim-btn");

      gsap.defaults({ ease: "power4", duration: 0.5 });
      gsap.set(".timeline-single-anim-btn", { opacity: 0, scaleY: 0.2 });

      ScrollTrigger.batch(".timeline-single-anim-btn", {
        onEnter: (batch) =>
          gsap.to(batch, {
            opacity: 1,
            scaleY: 1,
            stagger: 0.35,
            overwrite: true,
          }),
      });
    });
  }
}
