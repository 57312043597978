import store from "../store/index.js";

export default {
  data() {
    return {
      anredeForm: anrede ? "Sie" : "Du",
    };
  },
  methods: {
    returnHtml: function (jsonEntry) {
      return jsonEntry;
    },
    addCommas: function (nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "." + "$2");
      }
      return x1 + x2;
    },
    yearsAndMonths: function (m) {
      var years = m / 12;
      var fullYears = parseInt(years);
      var months = m % 12;
      if (months === 0) {
        if (fullYears === 1) {
          return `${fullYears} Jahr`;
        } else {
          return `${fullYears} Jahren`;
        }
      } else if (months === 1) {
        if (fullYears === 1) {
          return `${fullYears} Jahr und 1 Monat`;
        } else {
          return `${fullYears} Jahren und 1 Monat`;
        }
      } else {
        if (fullYears === 1) {
          return `${fullYears} Jahr und ${months} Monaten`;
        } else {
          return `${fullYears} Jahren und ${months} Monaten`;
        }
      }
    },
  },
  computed: {
    globalStore() {
      return store.getters.getState;
    },
    txtString() {
      // polite speech
      if (anrede) {
        return {
          general: {
            headline: `Ihr Wunschziel`,
            button: {
              forward: "Weiter",
              back: "Zurück",
              calculate: "Berechnen",
              more: "Weitere Details",
              reset: "Zurücksetzen",
            },
            calculators: {
              prePension: "Vorruhestand",
              timeout: "Auszeit",
              parttime: "Teilzeit",
              pension: "Geld im Alter",
            },
          },
          step1: {
            left: {
              info: "Was ist Ihr <strong>Wunschziel</strong>?",
            },
            right: {
              headline: "So einfach geht's",
              text: "Mit wenigen Schritten können Sie Ihr Ziel mit dem Kalkulator errechnen.",
            },
          },
          step2: {
            left: {
              slider1: `<strong>In</strong> wie vielen <strong>Jahren</strong> möchten Sie eine Auszeit nehmen?`,
              slider2:
                "<strong>In</strong> wie vielen <strong>Jahren</strong> möchten Sie in Teilzeit gehen?",
              slider3:
                "Auf wie viele Stunden soll Ihre wöchentliche Arbeitszeit <strong>in Stunden</strong> reduziert werden?",
              slider4:
                "Wie viele <strong>Monate</strong> soll Ihre Auszeit dauern?",
              slider5:
                "Wie viele <strong>Monate</strong> soll Ihre Teilzeit dauern?",
              slider6:
                "In welchem <strong>Alter</strong> möchten Sie in den Vorruhestand gehen?",
              slider7:
                "Wieviel <strong>% deines Gehalts</strong> wollen Sie in dieser Zeit erhalten?",
              textinput1:
                "Wieviel <strong>Gehalt</strong> wollen Sie in dieser Zeit erhalten?",
              slider8:
                "<strong>Mit welchem Alter</strong> wollen Sie in den <strong>Ruhestand</strong> gehen?",
              slider9:
                "Wie hoch soll die <strong>zusätzliche Rente</strong> sein?",
            },
            right: {
              headline: "So einfach geht's",
              text: "Mit wenigen Schritten können Sie Ihr Ziel mit dem Kalkulator errechnen.",
            },
          },
          step3: {
            headline: "Ihre Daten",
            left: {
              select1: "Wann ist Ihr <strong>Geburtstag</strong>?",
              select3: "Besitzen Sie ein <strong>Überstundenkonto</strong>?",
              slider1:
                "Wie viele <strong>Stunden</strong> arbeiten Sie pro <strong>Woche</strong>?",
              slider2: "Welche <strong>Steuerklasse</strong> haben Sie?",
              textinput1:
                "Wie hoch ist Ihr <strong>Bruttogehalt/Monat</strong>?",
            },
            right: {
              headline: "Weitere Details zur Berechnung",
              text: "Für eine detaillierte Auswertung können Sie weitere Daten wie z.B. Änderung der Rendite, bestehendes Guthaben zur Berechnung eintragen.",
            },
          },
          step4: {
            headline: "Ihre Stammdaten",
            left: {
              select1: "Bundesland",
              select2: "Geschlecht",
              select3: "Kinder",
              select4: "gesetzlich versichert",
              select5: "Kirchensteuer",
              select6: "Teilnahmebeginn",

              textinput1: "privat versichert (Betrag)",
              textinput2: "Steuerfreibetrag",
            },
            right: {
              headline: "Einzahlung / Anlage",
              textinput1: "Vorhandenes Guthaben",
              textinput2: "Arbeitgeberzuschuss im Monat",
              textinput3: "Jährliche Gehaltssteigerung in %",
              textinput4: "Rendite in %",
              select: "Sterbetafel",
              text: "Mit wenigen Schritten können Sie ihr Ziel mit dem Kalkulator errechnen.",
            },
          },
          step5: {
            headline: "Ihre Investition",
            left: {
              slider1: "<strong>Urlaubstage</strong> (im Jahr)",
              slider2: "<strong>Überstunden</strong> (im Monat)",
              slider3: "<strong>Renditeerwartung</strong> (pro Jahr)",
              slider4: "<strong>Arbeitgeberzuschuss</strong> (im Monat)",
              slider5: `<strong>vom Gehalt</strong> (im Monat) Brutto sparen, <strong>${this.addCommas(
                this.globalStore.netto_zahlen_monat
              )} €</strong> netto zahlen`,
              slider5Disabled:
                "Aus sozialversicherungsrechtlichen Gründen ist eine monatliche Sparrate für das aktuelle Auszahlungsziel nicht möglich.",
              slider5DisabledPopup: `Sie haben die sogenannten "70 - 130% - Grenze" erreicht. Das Sozialgesetz gibt vor, daß Ihr Auszeitgehalt nicht mehr als 130 % und nicht weniger als 70% Ihres aktuellen Bruttogehalts abzüglich der Sparrate vom Gehalt sein darf. Versuchen Sie es nocheinmal indem Sie z.B. wenn möglich die Anspardauer oder das Auszahlungsziel verändern, ${
                this.globalStore.theme.options.urlaub
                  ? "mehr Urlaubstage" +
                    (this.globalStore.theme.options.ueberstunden &&
                    this.globalStore.theme.options.sparen_jahr
                      ? ", "
                      : this.globalStore.theme.options.ueberstunden ||
                        this.globalStore.theme.options.sparen_jahr
                      ? "oder"
                      : " ")
                  : ""
              }${
                this.globalStore.theme.options.ueberstunden
                  ? "mehr Überstunden " +
                    (this.globalStore.theme.options.sparen_jahr ? "oder " : " ")
                  : " "
              }${
                this.globalStore.theme.options.sparen_jahr
                  ? "mehr Sonderzahlungen "
                  : " "
              } einbringen.`,
              slider5DiabledPopupTitle: "Rechtliche Hinweise",
              slider6: `<strong>Sonderzahlung</strong> (im Jahr) Brutto sparen, <strong>${this.addCommas(
                this.globalStore.netto_zahlen_sonderzahlungJahr
              )} €</strong> netto zahlen`,
            },
            right: {
              headline: "Ihr Ergebnis",
              button: {
                pdf: "PDF",
                details: "DETAILS",
              },
              textinput1: "Jahre/Monate bis zum Ziel",
              textinput2: "Monate Auszeit",
              textinput3: "Jahre/Monate bis zur Teilzeit",
              textinput4: "Monate Teilzeit",
              textinput5: "Beginn Vorruhestand",
              textinput6: "Rentenalter",
              textinput7: "Zusätzliche Rente/Monat",
              note: "Bei allen Berechnungen handelt es sich um modellhafte Darstellungen und erfolgen ohne Gewähr. Zudem erfolgt keine steuerliche Beratung.",
            },
            result: {
              isLess: `Leider haben sie Ihr Ziel erst zu <strong>${this.globalStore.anzeige_prozent} %</strong> erreicht.`,
              equals: "Sie haben Ihr <strong>Ziel erreicht</strong>.",
              isGreater: {
                pension: `Sie haben ihr Ziel erreicht und sogar  <strong>${this.addCommas(
                  Math.floor(
                    (this.globalStore.kapital +
                      this.globalStore.rendite_ueberschuss_rente -
                      this.globalStore.kapital_notwendig) /
                      100
                  ) * 100
                )} €</strong> Überschuss.`,
                time: `Sie haben Ihr Ziel erreicht und sogar  <strong>${this.addCommas(
                  Math.floor(
                    (this.globalStore.kapital - this.globalStore.zielkapital) /
                      100
                  ) * 100
                )} €</strong> Überschuss.`,
              },
            },
          },
          modal: {
            headline: "Details zu diesem Ergebnis",
            textTop: ` Mit ${this.addCommas(
              this.globalStore.details.input
            )} € Nettoeinsatz erreichen Sie eine Summe von ${this.addCommas(
              this.globalStore.details.output
            )} €. <p>Und so setzt sich dieses Kapital zusammen:</p>`,
            left: {
              vacation: "Urlaubstage*",
              overtime: "Überstunden*",
              saveMonth: "Monatsgehalt*",
              saveYear: "Sonderzahlungen*",
              return: "Rendite*",
              taxes: "Steuern- &amp; SV-Ersparnis*",
              employerSub: "Arbeitgeberzuschuss*",
              credit: "Vorhandenes Guthaben*",
              note: "* Schematische Darstellung: aus Vereinfachungsgründen wird auf volle 100 € gerundet.",
              notePension: `**Mtl. Brutto-Rente bei einer Lebenserwartung bis zum Alter ${this.globalStore.giaAlter} und einer Verzinsung des Gesamtkapitals von ${this.globalStore.rendite}% während der Auszahlungsphase.`,
            },
            right: {
              text: `Dank dieser Sparbausteine erreichen Sie mit gerade einmal ${this.addCommas(
                this.globalStore.details.input
              )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                this.globalStore.ansparzeit_monate
              )} bei einer konservativen Rendite von ${
                this.globalStore.rendite
              } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                this.globalStore.details.output
              )} €.`,
            },
          },
          pdf: {
            headline: "Ihr Wunschziel:",
            headlineAdoption: "Annahmen",
            headlineBaseData: "Stammdaten",
            headlineDetails: "Details zu Ihrem Ergebnis*",
            headlineResult: "Ihr Ergebnis",
            intro: {
              prePension: `Mit ${this.globalStore.wunsch_rentenalter} Jahren in den Vorruhestand gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
              timeOut: `In ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit nehmen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
              parttime: `In ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
              pension: `Mit ${this.globalStore.gia_alter}} Jahren in Rente gehen und dabei monatlich ${this.globalStore.zusatzrente} Euro zusätzlich zur Rente erhalten.`,
            },
            description:
              "Nachfolgend erhalten Sie die Zusammenfassung Ihrer Angaben und das Ergebnis der Berechnung.",
            columns: {
              born: "Geboren am",
              hours: "Wochenstunden",
              gSalary: "Brutto-gehalt im Monat",
              taxClass: "Steuerklasse",
              state: "Bundesland",
              curchTax: "Kirchensteuer",
              insurance: {
                txt: "Krankenversicherung",
                private: "Privat",
                statutory: "Gesetzlich",
              },
              credit: "Vorhandenes Guthaben",
              employerSub: "Arbeitgeberzuschuss im Monat",
              return: "Renditeerwartung p. a.",
              salaryTrend: "Gehaltstrend p. a.",
              startParti: "Teilnahmebeginn",
              partTimeWorkload: "Zielstunden in Teilzeit",
              vacationYear: "Urlaubstage (im Jahr)",
              overtimeMonth: "Überstunden (im Monat)",
              saveMonth: "Monatsgehalt brutto sparen (netto zahlen)",
              saveYear: "Sonderzahlungen im Jahr brutto sparen (netto zahlen)",
              prePension: {
                start: "Beginn des Vorruhestands",
                startLegally: "Beginn gesetzliche Rente",
                end: "Ende des Vorruhestands",
                payment: " Gehalt während des Vorruhestands",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              timeOut: {
                start: "Beginn der Auszeit",
                end: "Ende der Auszeit",
                payment: "Gehalt während der Auszeit",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              partTime: {
                start: "Beginn der Teilzeit",
                end: "Ende der Teilzeit",
                payment: "Gehalt während der Teilzeit",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              pension: {
                start: "Beginn der Rente",
                end: "Ende der Laufzeit",
                payment: "Zusatzrente",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              total: {
                capital: "Gesamtkapital",
                vacation: "Urlaubstage (im Jahr) / Summe",
                overtime: "Überstunden (im Monat) / Summe",
                saveMonth: "Monatsgehalt (netto zahlen) / Summe",
                saveYear: "Sonderzahlungen (pro Jahr, netto zahlen) / Summe",
                return: "Rendite",
                tax: "Steuer- und Sozialversicherungsersparnis",
                employerSub: "Arbeitgeberzuschuss im Monat",
              },
            },
            summary: {
              text: `Dank dieser Sparbausteine erreichen Sie mit gerade einmal ${this.addCommas(
                this.globalStore.details.input
              )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                this.globalStore.ansparzeit_monate
              )} bei einer konservativen Rendite von ${
                this.globalStore.rendite
              } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                this.globalStore.details.output
              )} €.`,
              prePension: `Damit können Sie es sich gönnen, mit ${this.globalStore.wunsch_rentenalter} Jahren in Vorruhestand zu gehen und das bei weiterhin <span>${this.globalStore.gehalt_prozent}</span> Prozent Ihres letzten Gehalts.`,
              timeOut: `Damit können Sie es sich gönnen, in ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit zu nehmen und das bei weiterhin <span>${this.globalStore.gehalt_prozent} Prozent</span> Ihres letzten Gehalts.`,
              partTime: `Damit können Sie es sich gönnen, in ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit zu gehen. Und das bei weiterhin <span> ${this.globalStore.gehalt_prozent} Prozent</span> Ihres letzten Gehalts.`,
              pension: `Damit können Sie es sich gönnen, mit ${
                this.globalStore.gia_alter
              } Jahren in Rente zu gehen und erhalten dabei jeden Monat zusätzlich <span> ${this.addCommas(
                this.globalStore.zusatzrente
              )} Euro</span> zu deiner Rente.`,
            },
            footer: {
              p1: "<strong>Für mehr Informationen</strong> wenden Sie sich an uns:",
              p2: `Telefon: <strong>${this.globalStore.theme.contact.phone}</strong> oder E-Mail: <strong>${this.globalStore.theme.contact.mail}</strong>`,
              note1:
                "Bitte beachten Sie, dass es sich um eine Modellberechnung handelt, die ohne Gewähr erfolgt.",
              note2: "Auch handelt es sich hier nicht um steuerliche Beratung.",
              created: `Erstellt am ${this.globalStore.datum}`,
            },
            units: {
              hour: {
                single: "Stunde",
                multi: "Stunden",
              },
              day: {
                single: "Tag",
                multi: "Tage",
              },
            },
          },
        };
      }

      // personal speech
      else {
        return {
          general: {
            headline: `Dein Wunschziel`,
            button: {
              forward: "Weiter",
              back: "Zurück",
              calculate: "Berechnen",
              more: "Weitere Details",
              reset: "Zurücksetzen",
            },
            calculators: {
              prePension: "Vorruhestand",
              timeout: "Auszeit",
              parttime: "Teilzeit",
              pension: "Geld im Alter",
            },
          },
          step1: {
            left: {
              info: "Was ist dein <strong>Wunschziel</strong>?",
            },
            right: {
              headline: "So einfach geht's",
              text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen.",
            },
          },
          step2: {
            left: {
              slider1: `<strong>In</strong> wie vielen <strong>Jahren</strong> möchtest du dir eine Auszeit nehmen?`,
              slider2:
                "<strong>In</strong> wie vielen <strong>Jahren</strong> möchtest du in Teilzeit gehen?",
              slider3:
                "Auf wie viele Stunden soll deine wöchentliche Arbeitszeit <strong>in Stunden</strong> reduziert werden?",
              slider4:
                "Wie viele <strong>Monate</strong> soll deine Auszeit dauern?",
              slider5:
                "Wie viele <strong>Monate</strong> soll deine Teilzeit dauern?",
              slider6:
                "In welchem <strong>Alter</strong> möchtest du in den Vorruhestand gehen?",
              slider7:
                "Wieviel <strong>% deines Gehalts</strong> willst du in dieser Zeit erhalten?",
              textinput1:
                "Wieviel <strong>Gehalt</strong> willst du in dieser Zeit erhalten?",
              slider8:
                "<strong>Mit welchem Alter</strong> willst du in den <strong>Ruhestand</strong> gehen?",
              slider9:
                "Wie hoch soll die <strong>zusätzliche Rente</strong> sein?",
            },
            right: {
              headline: "So einfach geht's",
              text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen.",
            },
          },
          step3: {
            headline: "Deine Daten",
            left: {
              select1: "Wann ist dein <strong>Geburtstag</strong>?",
              select3: "Besitzt du ein <strong>Überstundenkonto</strong>?",
              slider1:
                "Wie viele <strong>Stunden</strong> arbeitest du pro <strong>Woche</strong>?",
              slider2: "Welche <strong>Steuerklasse</strong> hast du?",
              textinput1:
                "Wie hoch ist dein <strong>Bruttogehalt/Monat</strong>?",
            },
            right: {
              headline: "Weitere Details zur Berechnung",
              text: "Für eine detaillierte Auswertung kannst du weitere Daten wie z.B. Änderung der Rendite, bestehendes Guthaben zur Berechnung eintragen.",
            },
          },
          step4: {
            headline: "Deine Stammdaten",
            left: {
              select1: "Bundesland",
              select2: "Geschlecht",
              select3: "Kinder",
              select4: "gesetzlich versichert",
              select5: "Kirchensteuer",
              select6: "Teilnahmebeginn",

              textinput1: "privat versichert (Betrag)",
              textinput2: "Steuerfreibetrag",
            },
            right: {
              headline: "Einzahlung / Anlage",
              textinput1: "Vorhandenes Guthaben",
              textinput2: "Arbeitgeberzuschuss im Monat",
              textinput3: "Jährliche Gehaltssteigerung in %",
              textinput4: "Rendite in %",
              select: "Sterbetafel",
              text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen.",
            },
          },
          step5: {
            headline: "Deine Investition",
            left: {
              slider1: "<strong>Urlaubstage</strong> (im Jahr)",
              slider2: "<strong>Überstunden</strong> (im Monat)",
              slider3: "<strong>Renditeerwartung</strong> (pro Jahr)",
              slider4: "<strong>Arbeitgeberzuschuss</strong> (im Monat)",
              slider5: `<strong>vom Gehalt</strong> (im Monat) Brutto sparen, <strong>${this.addCommas(
                this.globalStore.netto_zahlen_monat
              )} €</strong> netto zahlen`,
              slider5Disabled:
                "Aus sozialversicherungsrechtlichen Gründen ist eine monatliche Sparrate für das aktuelle Auszahlungsziel nicht möglich.",
              slider5DisabledPopup: `Du hat die sogenannten "70 - 130% - Grenze" erreicht. Das Sozialgesetz gibt vor, daß Dein Auszeitgehalt nicht mehr als 130 % und nicht weniger als 70% Deines aktuellen Bruttogehalts abzüglich der Sparrate vom Gehalt sein darf. Versuche es nocheinmal indem Du z.B. wenn möglich die Anspardauer oder das Auszahlungsziel veränderst, ${
                this.globalStore.theme.options.urlaub
                  ? "mehr Urlaubstage" +
                    (this.globalStore.theme.options.ueberstunden &&
                    this.globalStore.theme.options.sparen_jahr
                      ? ", "
                      : this.globalStore.theme.options.ueberstunden ||
                        this.globalStore.theme.options.sparen_jahr
                      ? "oder"
                      : " ")
                  : ""
              }${
                this.globalStore.theme.options.ueberstunden
                  ? "mehr Überstunden " +
                    (this.globalStore.theme.options.sparen_jahr ? "oder " : " ")
                  : " "
              }${
                this.globalStore.theme.options.sparen_jahr
                  ? "mehr Sonderzahlungen "
                  : " "
              } einbringst.`,
              slider5DiabledPopupTitle: "Rechtliche Hinweise",
              slider6: `<strong>Sonderzahlung</strong> (im Jahr) Brutto sparen, <strong>${this.addCommas(
                this.globalStore.netto_zahlen_sonderzahlungJahr
              )} €</strong> netto zahlen`,
            },
            right: {
              headline: "Dein Ergebnis",
              button: {
                pdf: "PDF",
                details: "DETAILS",
              },
              textinput1: "Jahre/Monate bis zum Ziel",
              textinput2: "Monate Auszeit",
              textinput3: "Jahre/Monate bis zur Teilzeit",
              textinput4: "Monate Teilzeit",
              textinput5: "Beginn Vorruhestand",
              textinput6: "Rentenalter",
              textinput7: "Zusätzliche Rente/Monat",
              note: "Bei allen Berechnungen handelt es sich um modellhafte Darstellungen und erfolgen ohne Gewähr. Zudem erfolgt keine steuerliche Beratung.",
            },
            result: {
              isLess: `Leider hast du dein Ziel erst zu <strong>${this.globalStore.anzeige_prozent} %</strong> erreicht.`,
              equals: "Du hast dein <strong>Ziel erreicht</strong>.",
              isGreater: {
                pension: `Du hast dein Ziel erreicht und sogar  <strong>${this.addCommas(
                  Math.floor(
                    (this.globalStore.kapital +
                      this.globalStore.rendite_ueberschuss_rente -
                      this.globalStore.kapital_notwendig) /
                      100
                  ) * 100
                )} €</strong> Überschuss.`,
                time: `Du hast dein Ziel erreicht und sogar  <strong>${this.addCommas(
                  Math.floor(
                    (this.globalStore.kapital - this.globalStore.zielkapital) /
                      100
                  ) * 100
                )} €</strong> Überschuss.`,
              },
            },
          },
          modal: {
            headline: "Details zu diesem Ergebnis",
            textTop: ` Mit ${this.addCommas(
              this.globalStore.details.input
            )} € Nettoeinsatz erreichst du eine Summe von ${this.addCommas(
              this.globalStore.details.output
            )} €. <p>Und so setzt sich dieses Kapital zusammen:</p>`,
            left: {
              vacation: "Urlaubstage*",
              overtime: "Überstunden*",
              saveMonth: "Monatsgehalt*",
              saveYear: "Sonderzahlungen*",
              return: "Rendite*",
              taxes: "Steuern- &amp; SV-Ersparnis*",
              employerSub: "Arbeitgeberzuschuss*",
              credit: "Vorhandenes Guthaben*",
              note: "* Schematische Darstellung: aus Vereinfachungsgründen wird auf volle 100 € gerundet.",
              notePension: `**Mtl. Brutto-Rente bei einer Lebenserwartung bis zum Alter ${this.globalStore.giaAlter} und einer Verzinsung des Gesamtkapitals von ${this.globalStore.rendite}% während der Auszahlungsphase.`,
            },
            right: {
              text: `Dank dieser Sparbausteine erreichst du mit gerade einmal ${this.addCommas(
                this.globalStore.details.input
              )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                this.globalStore.ansparzeit_monate
              )} bei einer konservativen Rendite von ${
                this.globalStore.rendite
              } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                this.globalStore.details.output
              )} €.`,
            },
          },
          pdf: {
            headline: "Dein Wunschziel:",
            headlineAdoption:
              "Annahmen (Selbstständige Eingabe unter „weitere Details“ im Rechner)",
            headlineBaseData: "Stammdaten",
            headlineDetails: "Details zu Deinem Ergebnis*",
            headlineResult: "Dein Ergebnis",
            intro: {
              prePension: `Mit ${this.globalStore.wunsch_rentenalter} Jahren in den Vorruhestand gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Einkommen beziehen`,
              timeOut: `In ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit nehmen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Einkommen beziehen`,
              parttime: `In ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Einkommen beziehen`,
              pension: `Mit ${this.globalStore.gia_alter}} Jahren in Rente gehen und dabei monatlich ${this.globalStore.zusatzrente} Euro zusätzlich zur Rente erhalten.`,
            },
            description:
              "Nachfolgend erhältst du das Ergebnis deiner Berechnung.",
            columns: {
              born: "Geboren im",
              hours: "Wochenstunden",
              gSalary: "Brutto-Einkommen / Monatseinkommen",
              taxClass: "Steuerklasse",
              state: "Bundesland",
              curchTax: "Kirchensteuer",
              insurance: {
                txt: "Krankenversicherung",
                private: "Privat",
                statutory: "Gesetzlich",
              },
              credit: "Vorhandenes Guthaben",
              employerSub: "Arbeitgeberzuschuss im Monat",
              return: "Renditeerwartung p. a.",
              salaryTrend: "Gehaltstrend p. a.",
              startParti: "Teilnahmebeginn",
              partTimeWorkload: "Zielstunden in Teilzeit",
              vacationYear: "Urlaubstage (im Jahr)",
              overtimeMonth: "Überstunden (im Monat)",
              saveMonth: "Monatseinkommen brutto sparen (netto zahlen)",
              saveYear: "Sonderzahlungen im Jahr brutto sparen (netto zahlen)",
              prePension: {
                start: "Beginn des Vorruhestands",
                startLegally: "Beginn gesetzliche Rente",
                end: "Ende des Vorruhestands",
                payment: " Einkommen während des Vorruhestands",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              timeOut: {
                start: "Beginn der Auszeit",
                end: "Ende der Auszeit",
                payment: "Einkommen während der Auszeit",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              partTime: {
                start: "Beginn der Teilzeit",
                end: "Ende der Teilzeit",
                payment: "Einkommen während der Teilzeit",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              pension: {
                start: "Beginn der Rente",
                end: "Ende der Laufzeit",
                payment: "Zusatzrente",
                excess: "Überschuss (+) / Unterdeckung (-)",
              },
              total: {
                capital: "Gesamtkapital",
                vacation: "Urlaubstage (im Jahr) / Summe",
                overtime: "Überstunden (im Monat) / Summe",
                saveMonth: "Monatseinkommen (netto zahlen) / Summe",
                saveYear: "Sonderzahlungen (pro Jahr, netto zahlen) / Summe",
                return: "Rendite",
                tax: "Steuer- und Sozialversicherungsersparnis",
                employerSub: "Arbeitgeberzuschuss im Monat",
              },
            },
            summary: {
              text: `Dank dieser Sparbausteine erreichst du mit gerade einmal ${this.addCommas(
                this.globalStore.details.input
              )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Einkommen &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                this.globalStore.ansparzeit_monate
              )} bei einer konservativen Rendite von ${
                this.globalStore.rendite
              } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                this.globalStore.details.output
              )} €.`,
              prePension: `Damit kannst du es dir gönnen, mit ${this.globalStore.wunsch_rentenalter} Jahren in Vorruhestand zu gehen und das bei weiterhin <span>${this.globalStore.gehalt_prozent}</span> Prozent deines letzten Gehalts.`,
              timeOut: `Damit kannst du es dir gönnen, in ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit zu nehmen und das bei weiterhin <span>${this.globalStore.gehalt_prozent} Prozent</span> deines letzten Gehalts.`,
              partTime: `Damit kannst du es dir gönnen, in ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit zu gehen. Und das bei weiterhin <span> ${this.globalStore.gehalt_prozent} Prozent</span> deines letzten Gehalts.`,
              pension: `Damit kannst du es dir gönnen, mit ${
                this.globalStore.gia_alter
              } Jahren in Rente zu gehen und erhälst dabei jeden Monat zusätzlich <span> ${this.addCommas(
                this.globalStore.zusatzrente
              )} Euro</span> zu deiner Rente.`,
            },
            footer: {
              p1: "<strong>Für mehr Informationen</strong> wende dich an uns:",
              p2: `E-Mail: <strong>${this.globalStore.theme.contact.mail}</strong>`,
              note1:
                "Bitte beachte, dass es sich um eine Modellberechnung handelt, die ohne Gewähr erfolgt.",
              note2: "Auch handelt es sich hier nicht um steuerliche Beratung.",
              created: `Erstellt am ${this.globalStore.datum}`,
            },
            units: {
              hour: {
                single: "Stunde",
                multi: "Stunden",
              },
              day: {
                single: "Tag",
                multi: "Tage",
              },
            },
          },
        };
      }
    },
  },
  mounted() {},
};
