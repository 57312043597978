<template>
<div id="colorbar" class="colorbar">
    <div v-if="getState.colorbar.green>0" class="green" v-bind:style="{ width: getState.colorbar.green+ '%' }"></div>
    <div v-if="getState.colorbar.brown>0" class="brown" v-bind:style="{ width: getState.colorbar.brown+ '%' }"></div>
    <div v-if="getState.colorbar.yellow>0" class="yellow" v-bind:style="{ width: getState.colorbar.yellow+ '%' }"></div>
    <div v-if="getState.colorbar.orange>0" class="orange" v-bind:style="{ width: getState.colorbar.orange+ '%' }"></div>
    <div v-if="getState.colorbar.red>0" class="red" v-bind:style="{ width: getState.colorbar.red+ '%' }"></div>
    <div v-if="getState.colorbar.purple>0" class="purple" v-bind:style="{ width: getState.colorbar.purple+ '%' }"></div>
    <div v-if="getState.colorbar.blue>0" class="blue" v-bind:style="{ width: getState.colorbar.blue+ '%' }"></div>
    <div v-if="getState.colorbar.lightblue>0" class="lightblue" v-bind:style="{ width: getState.colorbar.lightblue+ '%' }"></div>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Colorbar",
  methods: {},
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
