var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container is-medium"},[_c('div',{staticClass:"columns is-3 is-multiline step-container"},[_c('div',{staticClass:"column is-12-touch is-8-desktop"},[_c('div',{staticClass:"part-left"},[_c('div',{staticClass:"subline card-title"},[_c('Arrow'),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.returnHtml(_vm.txtString.general.headline) || '')}})],1),_vm._v(" "),_c('div',{},[_c('div',{staticClass:"ziele"},[_c('div',{staticClass:"columns is-centered is-mobile is-multiline"},[_c('div',{class:_vm.getState.theme.options.auszeit === true ? 'is-hidden' : ''}),_vm._v(" "),_c('div',{staticClass:"column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd",class:_vm.getState.theme.options.auszeit === false
                      ? 'is-hidden'
                      : '',on:{"click":function($event){return _vm.onSelect(_vm.ziel1.name)}}},[(_vm.getState.theme.options.auszeit)?_c('Ziel',{ref:"ziel",attrs:{"setup":_vm.ziel1}}):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.getState.theme.options.rente === true ? 'is-hidden' : ''}),_vm._v(" "),_c('div',{staticClass:"column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd",class:_vm.getState.theme.options.rente === false ? 'is-hidden' : '',on:{"click":function($event){return _vm.onSelect(_vm.ziel2.name)}}},[(_vm.getState.theme.options.rente)?_c('Ziel',{ref:"ziel",attrs:{"setup":_vm.ziel2}}):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.getState.theme.options.teilzeit === true
                      ? 'is-hidden'
                      : ''}),_vm._v(" "),_c('div',{staticClass:"column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd",class:_vm.getState.theme.options.teilzeit === false
                      ? 'is-hidden'
                      : '',on:{"click":function($event){return _vm.onSelect(_vm.ziel3.name)}}},[(_vm.getState.theme.options.teilzeit)?_c('Ziel',{ref:"ziel",attrs:{"setup":_vm.ziel3}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd",class:_vm.getState.theme.options.geldImAlter === true
                      ? 'is-hidden'
                      : ''}),_vm._v(" "),_c('div',{staticClass:"column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd",class:_vm.getState.theme.options.geldImAlter === false
                      ? 'is-hidden'
                      : '',on:{"click":function($event){return _vm.onSelect(_vm.ziel4.name)}}},[(_vm.getState.theme.options.geldImAlter)?_c('Ziel',{ref:"ziel",attrs:{"setup":_vm.ziel4}}):_vm._e()],1),_vm._v(" "),(
                    _vm.getState.theme.options.auszeit === false ||
                    _vm.getState.theme.options.rente === false ||
                    _vm.getState.theme.options.teilzeit === false
                  )?_c('div',{staticClass:"column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd"}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"description_ziele",domProps:{"innerHTML":_vm._s(_vm.returnHtml(_vm.txtString.step1.left.info) || '')}})]),_vm._v(" "),_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-item"}),_vm._v(" "),_c('div',{staticClass:"level-right",on:{"click":function($event){return _vm.navigate(2)}}})])])]),_vm._v(" "),_c('div',{staticClass:"column is-12-touch is-4-desktop"},[_c('div',{staticClass:"fix-safari-desktop"},[_c('div',{staticClass:"part-right"},[_c('div',{staticClass:"is-relative"},[_c('Arrow'),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.returnHtml(_vm.txtString.step1.right.headline) || '')}}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.returnHtml(_vm.txtString.step1.right.text) || '')}})],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }