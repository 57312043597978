<template>
  <div class="modal-calcy">
      <div class="modal-content-calcy">
        <div class="modal-header">
          <div class="arrow">
            <img class="pfeil" :src="arrow" alt="" />
          </div>
          <span v-show="withCloseIcon" class="close" @click="closeModal">&times;</span>
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "ModalRechner",
    props: ["withCloseIcon"],
    data () {
        return {
            anrede: null,
            // eslint-disable-next-line no-undef
            arrow: pdf_arrow_url
        }
    },
    methods: {
        closeModal () {
            this.$emit("close")
        }
    }
}

</script>

<style scoped lang="scss">

.modal-calcy {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  align-content: center;

  .modal-header {
    line-height: 40px;
    text-align: center;
    .arrow{
      height: 28px;
      width: 55px;
      display: inline-block;
      margin-right: 10px;
    }
    h3 {
      display: inline-block;
    }
  }

  .modal-content-calcy {
    position: absolute;
    background-color: #fefefe;
    // fallback
    margin: auto 20%;
    margin: auto 20vw; /* 15% from the top and centered */
    padding: 100px;
    border: 1px solid #888;
    border-radius: 1rem;
    // fallback
    width: 60%;
    width: 60vw;
    max-height: 90vh;
    overflow-y: auto;
    @media (max-width: 768px) {
      width: 100%;
      margin: auto 0;
    }
  }

  .modal-body{
    padding-top: 50px;
  }
  /* The Close Button */
  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
