<template>
  <section class="section">
    <div class="container is-medium">
      <div class="columns is-3 is-multiline step-container">
        <div class="column is-12-touch is-8-desktop">
          <div class="part-left">
            <div class="container">
              <div class="is-relative subline">
                <Arrow />
                <span v-html="returnHtml(txtString.step4.headline) || ''">
                  <!-- Headline -->
                </span>
              </div>
            </div>
            <div class="container">
              <div class="control">
                <div class="columns">
                  <div class="column is-relative">
                    <Select :setup="select1" :options="states" />
                    <div
                      v-html="returnHtml(txtString.step4.left.select1) || ''"
                      class="description"
                    >
                      <!-- Bundesland -->
                    </div>
                  </div>
                  <div
                    v-if="getState.aktives_ziel === 'geldImAlter'"
                    class="column is-relative"
                  >
                    <Select
                      :setup="select7"
                      :options="gender"
                      :key="getState.keychange + 'select7'"
                    />
                    <div
                      v-html="returnHtml(txtString.step4.left.select2) || ''"
                      class="description"
                    >
                      <!-- Geschlecht -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="columns">
                <div class="column is-relative">
                  <Textinput :setup="textinput1" />
                  <div
                    v-html="returnHtml(txtString.step4.left.textinput1) || ''"
                    class="description"
                  >
                    <!-- privat Versicher Betrag -->
                  </div>
                </div>

                <div class="column is-relative">
                  <Select :setup="select2" :options="kids" />
                  <div
                    v-html="returnHtml(txtString.step4.left.select3) || ''"
                    class="description"
                  >
                    <!-- Kinder -->
                  </div>
                </div>
                <div class="column is-relative">
                  <Select
                    :setup="select3"
                    :options="boolean"
                    ref="gesetzlichVersichert"
                  />
                  <div
                    v-html="returnHtml(txtString.step4.left.select4) || ''"
                    class="description"
                  >
                    <!-- gesetzlich versichert -->
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="columns">
                <div class="column is-relative">
                  <Select :setup="select4" :options="boolean" />
                  <div
                    v-html="returnHtml(txtString.step4.left.select5) || ''"
                    class="description"
                  >
                    <!-- Kirchensteuer -->
                  </div>
                </div>

                <div class="column is-relative">
                  <Textinput :setup="textinput2" />
                  <div
                    v-html="returnHtml(txtString.step4.left.textinput2) || ''"
                    class="description"
                  >
                    <!-- Steuerfreigetrag -->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="container"
              v-if="
                getState.aktives_ziel === 'geldImAlter' ||
                getState.aktives_ziel === 'rente'
              "
            >
              <div class="control">
                <div class="columns">
                  <div class="column is-8">
                    <Select :setup="select5" :options="months" />
                  </div>

                  <div class="column is-4">
                    <Select
                      :setup="select6"
                      :options="years"
                      :key="getState.keychange + 'selectYear4'"
                    />
                  </div>
                </div>
              </div>
              <div
                v-html="returnHtml(txtString.step4.left.select6) || ''"
                class="description"
              >
                <!-- Teilnahmebeginn -->
              </div>
            </div>
            <div v-else class="container is-hidden-touch">
              <div class="control">
                <div class="columns">
                  <div class="column is-12">
                  </div>
                </div>
              </div>
            </div>
            <div class="container is-hidden-touch">
              <div class="columns">
                <div class="column"></div>
              </div>
            </div>
            <div class="level is-mobile is-hidden-touch">
              <div
                v-html="returnHtml(txtString.general.button.back) || ''"
                class="level-left is-hidden-touch button back"
                v-on:click="navigate(3)"
              >
                <!-- Button Zurück -->
              </div>
              <div class="level-item"></div>
            </div>
          </div>
        </div>
        <div class="column is-12-touch is-4-desktop">
          <div class="fix-safari-desktop">
          <div class="options">
            <div class="top">
              <div class="container">
                <div class="is-relative subline">
                  <span
                    v-html="returnHtml(txtString.step4.right.headline) || ''"
                  >
                    <!-- Headline -->
                  </span>
                </div>
              </div>

              <div class="container">
                <div class="columns">
                  <div class="column is-relative">
                    <Textinput :setup="textinput4" />
                    <div
                      v-html="
                        returnHtml(txtString.step4.right.textinput1) || ''
                      "
                      class="description"
                    >
                      <!-- Vorhandenes Guthaben -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  getState.aktives_ziel !== 'geldImAlter' & getState.theme.options.arbeitgeberzuschuss"
                class="container"
              >
                <div class="columns">
                  <div class="column is-relative">
                    <Textinput
                      :setup="textinput5"
                      :key="getState.keychange + 'input5'"
                    />
                    <div
                      v-html="
                        returnHtml(txtString.step4.right.textinput2) || ''
                      "
                      class="description"
                    >
                      <!-- Arbeitgeberzuschuss pro Monat -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="columns">
                  <div class="column is-relative">
                    <Textinput :setup="textinput6" />
                    <div
                      v-html="
                        returnHtml(txtString.step4.right.textinput3) || ''
                      "
                      class="description"
                    >
                      <!-- Jährliche Gehaltssteigerung in % -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  getState.aktives_ziel === 'auszeit' ||
                  getState.aktives_ziel === 'rente' ||
                  getState.aktives_ziel === 'teilzeit'
                "
                class="container"
              >
                <div class="columns">
                  <div class="column is-relative">
                    <Textinput
                      :setup="textinput7"
                      :key="getState.keychange + 'input7'"
                    />
                    <div
                      v-html="
                        returnHtml(txtString.step4.right.textinput4) || ''
                      "
                      class="description"
                    >
                      <!-- Rendite in % -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="getState.aktives_ziel === 'geldImAlter'"
                class="container"
              >
                <div class="columns">
                  <div class="column is-relative">
                    <Select
                      :setup="select8"
                      :options="lifeTable"
                      :key="getState.keychange + 'select8'"
                    />
                    <div
                      v-html="returnHtml(txtString.step4.right.select) || ''"
                      class="description"
                    >
                      <!-- Sterbetafel -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="level is-mobile">
              <div
                v-html="returnHtml(txtString.general.button.back) || ''"
                class="level-left is-hidden-desktop button back"
                v-on:click="navigate(3)"
              >
                <!-- Button Zurück -->
              </div>
              <div class="level-item is-hidden-mobile"></div>
              <div
                v-html="returnHtml(txtString.general.button.forward) || ''"
                :style="forwardButton"
                class="level-right button forward"
                v-on:click="navigate(5)"
              >
                <!-- Button Weiter -->
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <ModalNotPossible v-if="showModalNotPossible" @click-sparzielAendern="navigate(2, true)"></ModalNotPossible>
    <ModalRechnerOver130Under70 v-else-if="showModal" @click-sparzielAendern="navigate(2, true)" @click-zumErgebnis="navigate(5, true)"></ModalRechnerOver130Under70>
  </section>
</template>
<script>
import Select from "../components/Select.vue"
import Arrow from "../svg/Arrow.vue"
import Textinput from "../components/Textinput.vue"
import ModalRechnerOver130Under70 from "../components/ModalOver130Under70.vue"
import ModalNotPossible from "../components/ModalNotPossible.vue"
export default {
    name: "Step4",
    components: {
        ModalRechnerOver130Under70,
        ModalNotPossible,
        Select,
        Textinput,
        Arrow

    },
    data: () => {
        return {
            modalOver130: null,
            select1: { id_tag: "bundesland" },
            select2: { id_tag: "kinder" },
            select3: { id_tag: "gesetzlich_versichert_go" },
            select4: { id_tag: "kirchensteuer" },
            select5: { id_tag: "teilnahme_monat" },
            select6: { id_tag: "teilnahme_jahr" },
            select7: { id_tag: "geschlecht" },
            select8: { id_tag: "sterbetafel" },
            textinput1: {
                id_tag: "privat_versichert",
                placeholder: "0",
                unit: "€"
            },
            textinput2: {
                id_tag: "steuerfreibetrag",
                placeholder: "0",
                unit: "€"
            },

            textinput4: {
                id_tag: "vorhandenes_guthaben",
                placeholder: "0",
                unit: "€"
            },
            textinput5: {
                id_tag: "zuschuss_arbeitgeber",
                placeholder: "0",
                unit: "€"
            },
            textinput6: {
                id_tag: "gehaltssteigerung",
                placeholder: "0",
                calculate_on_input: false,
                inputtype: "number",
                min: 0,
                max: 10
            },
            textinput7: {
                id_tag: "rendite",
                placeholder: "0",
                calculate_on_input: false,
                inputtype: "number",
                step: 0.5,
                min: 0,
                max: 6
            },
            anrede: null,
            showModal: false,
            showModalNotPossible: false
        }
    },
    methods: {
        navigate: function (direction, fromModal) {
            // Recalculate on navigate to check for rules
            this.$store.commit("init_values", { calctype: "bare" })
            this.$store.commit("calc_sparrate", { calctype: "sparrate" })
            this.$store.commit("init_values", { calctype: "sMonat" })
            this.$store.commit("init_values", { calctype: "sJahr" })
            this.$store.commit("colorbar", {})
            if (!fromModal && this.getState.notPossible && direction === 5) {
                this.openModalNotPossible()
            } else
            if (!fromModal && (this.getState.over130 || this.getState.under70) && direction === 5) {
                this.openModal()
            } else {
                this.closeModal()
                this.closeModalNotPossible()
                this.$emit("navigate", direction)
            }
            document.getElementById("app").scrollIntoView()
        },
        openModal () {
            this.showModal = true
        },
        closeModal () {
            this.showModal = false
        },
        openModalNotPossible () {
            this.showModalNotPossible = true
        },
        closeModalNotPossible () {
            this.showModalNotPossible = false
        }
    },
    created () {},
    computed: {
        states () {
            const states = [
                "Baden-Württemberg",
                "Bayern",
                "Berlin (West)",
                "Berlin (Ost)",
                "Brandenburg",
                "Bremen",
                "Hamburg",
                "Hessen",
                "Mecklenburg-Vorpommern",
                "Niedersachsen",
                "Nordrhein-Westfalen",
                "Rheinland-Pfalz",
                "Saarland",
                "Sachsen-Anhalt",
                "Sachsen",
                "Schleswig-Holstein",
                "Thüringen"
            ]
            return states
        },
        kids () {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        gender () {
            return ["männlich", "weiblich"]
        },
        lifeTable () {
            return [
                "Unisex-Tarif Versicherer",
                "DAV2004R",
                "Allgemeine Sterbetafel Bundesamt"
            ]
        },
        boolean () {
            return ["Ja", "Nein"]
        },

        years () {
            if (this.getState.aktives_ziel === "geldImAlter") {
                const actualYear = new Date().getFullYear()
                return Array.from(
                    {
                        length:
              actualYear +
              this.getState.gia_alter -
              1 -
              actualYear -
              (actualYear - this.getState.geburt_jahr)
                    },
                    (value, index) => actualYear + index
                )
            } else if (this.getState.aktives_ziel === "rente") {
                const actualYear = new Date().getFullYear()
                return Array.from(
                    {
                        length:
              actualYear +
              this.getState.wunsch_rentenalter -
              1 -
              actualYear -
              (actualYear - this.getState.geburt_jahr)
                    },
                    (value, index) => actualYear + index
                )
            }
        },
        months () {
            const allMonths = [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember"
            ]
            return allMonths
        },
        getState () {
            return this.$store.getters.getState
        },
        forwardButton () {
            return (
                "color: " +
        this.getState.theme.color.arrow +
        " ; border-color: " +
        this.getState.theme.color.arrow
            )
        }
    }
}
</script>
<style scoped lang="scss">
.section {
  padding: 0;
  .title {
    display: flex;
    justify-content: center;
    width: auto;
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 600;
  }
  .column {
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .columns {
    .part-left {
      .container {
        margin-bottom: 2rem;
      }
    }
  }
}
</style>
