<template>
  <div class="slider is-relative">
    <input
      :style="getState[slider_id_max] === 0 || max_value===0 ? {} : style_slider + (slider_id === 'sparen_gehalt_last_year' && getState[slider_id_min] > 0 ? 'border-radius: 0px !important;': '')"
      v-bind:id="slider_id"
      class="slider"
      v-bind:step="getState[slider_id_max] === 0 || max_value===0 ? 0 : value_steps"
      v-bind:min="getState[slider_id_min] ? getState[slider_id_min] : min_value"
      v-bind:max="getState[slider_id_max] ? getState[slider_id_max] : max_value"
      v-bind:disabled="getState[slider_id_max] === 0 || max_value===0"
      v-bind:value="getState[slider_id]"
      type="range"
      v-on:input="handleSlide($event)"
    />
    <output class="bubble" v-bind:style="getState[slider_id_max] === 0 || max_value===0 ? {display: 'none'} : {}" v-bind:id="slider_id + '_output'" for="slider-1">
      <span :style="valueMark" class="is-hidden-touch">❮</span>
      <div class="display" v-if="inputbox === false">
        {{ addCommas(getState[slider_id]) }}{{ tooltip_unit }}
      </div>
      <div class="number-container" v-else>
        <input
          v-bind:id="slider_id + '_input'"
          class="bubbleinput has-text-centered"
          v-autowidth="{ maxWidth: 'auto', minWidth: '0px', comfortZone: 20 }"
          v-on:keyup="onChange($event)"
          v-bind:value="addCommas(getState[slider_id])"
          :min="getState[slider_id_min] ? getState[slider_id_min] : min_value"
          :max="getState[slider_id_max] ? getState[slider_id_max] : max_value"
        />{{ tooltip_unit }}
      </div>
      <span :style="valueMark" class="is-hidden-touch">❯</span>
    </output>
    <div class="endsign"><span>+</span></div>
  </div>
</template>

<script>
import { bus } from "../main";
import { mapState } from "vuex";
import Vue, { readonly } from "vue"
import VueInputAutowidth from "vue-input-autowidth";
Vue.use(VueInputAutowidth);

export default {
  name: "Slider",
  props: ["setup"],

  data: function () {
    return {
      // slider_value: this.setup.start,
      type: "number",
      min_value: this.min ? this.min : this.setup.min,
      max_value: this.max ? this.max : this.setup.max,
      value_steps: this.setup.step,
      slider_id: this.setup.id_tag,
      slider_id_max: this.setup.id_tag + "_max",
      slider_id_min: this.setup.id_tag === "sparen_gehalt" ? "" : this.setup.id_tag + "_min",
      tooltip_unit: this.setup.unit ? " " + this.setup.unit : "",
      factor: this.setup.multiplier ? this.setup.multiplier : 1,
      inputbox: this.setup.double_input ? this.setup.double_input : false,
      recalculate: this.setup.calculate_on_input === true ? true : false,
      // my_value: 0,
    };
  },
  methods: {
    handleSlide: function (event) {
      if (event !== null) {
        this.$store.commit("change_value", {
          id: this.slider_id,
          value: event.target.value * this.factor,
        });
      }

      var tooltip = document.querySelector(`#${this.slider_id + "_output"}`);
      var slider = document.querySelector(`#${this.slider_id}`);
      if (slider) {
        var px =
          ((slider.valueAsNumber - parseInt(slider.min)) * 100) /
          (parseInt(slider.max) - parseInt(slider.min));
        if (tooltip) {
          tooltip.style.left = `calc(${px}% + (${10 - px * 0.23}px))`;
          tooltip.style.top = -slider.offsetHeight - 50 + "px";
        }

        slider.style.backgroundSize = `${px}% 100%`;
      }
    },
    onChange: function (event) {
      var input_value;
      if (event.target.value > 0 && event.target.value !== "undefined") {
        input_value = parseInt(event.target.value.replace(".", ""));
      } else if (isNaN(event.target.value)) {
        input_value = 0;
        event.target.value = 0;
      } else {
        input_value = 0;
        event.target.value = 0;
      }

      //  setTimeout(() => {
      this.$store.commit("change_value", {
        id: this.slider_id,
        value: input_value * this.factor,
      });

      var inputwitdh = this.getState[this.slider_id].toString().length * 15;
      document.querySelector(
        `#${this.slider_id + "_input"}`
      ).style.width = `${inputwitdh}px`;

      if (this.recalculate === true) {
        this.$store.commit("init_values", { calctype: "sJahr" });
        this.$store.commit("init_values", { calctype: "sMonat" });
        this.$store.commit("init_values", { calctype: "bare" });
      }
      var slider = document.querySelector(`#${this.slider_id}`);
      slider.valueAsNumber = event.target.value;
      this.handleSlide(null);
      //  }, 1000);
      // event.target.value=addCommas(input_value)
    },

    addCommas: function (nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "." + "$2");
      }
      return x1 + x2;
    },
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style_slider() {
      return (
        "background-image: -webkit-gradient(linear,50% 0%,50% 100%, color-stop(0%, " +
        this.getState.theme.color.arrow +
        "), color-stop(100%, " +
        this.getState.theme.color.arrow +
        "));"
      );
    },
    valueMark() {
      return "color: " + this.getState.theme.color.arrow;
    },
  },

  mounted() {
    this.handleSlide(null);
    bus.$on("calibrate_slider", () => {
      this.handleSlide(null);
    });
    window.addEventListener("resize", () => {
      this.handleSlide(null);
    });
  },
  updated: function () {
    this.handleSlide(null);
    bus.$on("calibrate_slider", () => {
      this.handleSlide(null);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slider {
  position: relative;
}

output {
  transform: translate(-47%, 8px);

  position: absolute;
  padding: 0.3rem;
  border: 1px solid black;
  // border-radius: 0.5rem;
  font-size: 1.3rem;
  // font-weight: bolder;
  display: block;
  white-space: nowrap;
  width: min-content;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding: 0.3rem 1rem;
    }
  }

  .number-container {
    width: 100%;
  }
  span {
    margin-bottom: 0.3rem;
    display: inline;
    font-weight: normal;
    font-size: 1.3rem;
    color: #51aae0;

    &:first-of-type {
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          position: absolute;
          left: 0.1rem;
        }
      }
    }

    &:nth-of-type(2) {
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          position: absolute;
          right: 0.1rem;
        }
      }
    }
  }
  div {
    display: inline;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        margin-right: 2rem !important;
        margin-left: 0 !important;
      }
    }
  }

  :first-child {
    display: inline;
    // margin-right: 0.5rem;
  }
  :last-child {
    display: inline;
    // margin-left: 0.5rem;
  }
  &::after {
    content: "";
    bottom: -9px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    height: 17.5px;
    width: 18px;
    border-radius: 1px;
    background-color: white;
    transform: rotate(-45deg);
  }
}
</style>
