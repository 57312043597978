export function wikiAjax() {
    var loading = document.getElementById('loading_animation');
    var container = document.getElementById('container-ajax')
    var button = document.getElementById('loadmore');
    gp_params.current_page = 0;

    if (button) {
        button.addEventListener('click', function() {
            // console.log(steven_loadmore_params.ajaxurl)
            var beforeSend = function () {
                container.innerHTML = ''
                loading.classList.toggle('is-hidden')
                button.style.display = 'none';
            }
            beforeSend()
            var xhr = new XMLHttpRequest();
            xhr.open('POST', gp_params.ajaxurl);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
        
                if (xhr.status === 200) {
                    if (xhr.responseText) {
                        loading.classList.toggle('is-hidden')
                        button.style.display = 'inline-flex'
                        container.innerHTML = xhr.response;
                        gp_params.current_page++;
                        var max = 0
                        max = document.getElementById('max-page');
                        gp_params.max_page = max.dataset.maxPage;
                        if (gp_params.current_page >= gp_params.max_page) {
                            button.style.display = 'none';
                        }
                    } else {
                        // console.log('no post')
                        button.style.display = 'none';
                    }
                }
            }
            xhr.send("action=filter_pressetext&page=" + gp_params.current_page)
            return false;
        });
    }
}