export function Animations() {

  const sections = document.querySelectorAll('main > .section');
  const circle = document.querySelectorAll('.circle');
  const pensCircle = document.querySelectorAll('.pens-circle');
  const overlayText = document.querySelectorAll('.is-overlay-text');
  const calculatorSection = document.querySelectorAll('.calculator');
  var calcy;
  if (document.getElementsByClassName('calcy')[0]) {
    calcy = document.getElementsByClassName('calcy')[0];
  }
  else {
    calcy = false
  }

  var observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('fade');
      } else {
        entry.target.classList.remove('fade');
      }
    });
  });

  var observerCircle = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('circle-animation');
      } else {
        entry.target.classList.remove('circle-animation');
      }
    });
  });

  var observerPensCircle = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('circle-animation');
      } else {
        entry.target.classList.remove('circle-animation');
      }
    });
  });

  var observerText = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('left-fade');
      } else {
        entry.target.classList.remove('left-fade');
      }
    });
  });

  var options = { root: null, threshold: 0, rootMargin: '-80px' };

  var observerCalculatorSection = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (calcy && entry.isIntersecting) {
        calcy.classList.add('is-hidden');
      } else {
        if (calcy) {
          calcy.classList.remove('is-hidden');
        }
      }
    });
  }, options);

  // sections.forEach(sec => {
  //   observer.observe(sec);
  // });

  circle.forEach(circ => {
    observerCircle.observe(circ);
  });

  pensCircle.forEach(circ => {
    observerPensCircle.observe(circ);
  });

  overlayText.forEach(circ => {
    observerText.observe(circ);
  });

  calculatorSection.forEach(calc => {
    observerCalculatorSection.observe(calc);
  });

}
