<template>
  <svg
    class="pfeil"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    aria-labelledby="iconName"
    style="enable-background: new 0 0 80 80"
    xml:space="preserve"
    role="presentation"
    x="0px"
    y="0px"
  >
    <title id="iconName" lang="en">icon</title>
    <g id="Ebene_1">
      <path
        :style="style1"
        class="st1"
        d="M44.219967,50.798157v-0.005802h-0.11319c-0.027245-0.000389-0.053528-0.004128-0.080769-0.004128
		c-0.02737,0-0.053783,0.003738-0.080898,0.004128h-5.403374c-0.027241-0.000389-0.053524-0.004128-0.080769-0.004128
		c-0.02737,0-0.05378,0.003738-0.080894,0.004128h-0.020466v0.00103c-3.212196,0.054985-5.800499,2.692684-5.800499,5.943718
		l-0.24691,4.256416l0.522659-1.668156l-0.177525,1.185287h-0.106922v1.448673h17.377457v-0.965805v-0.482868v-3.773548
		C49.927868,53.517654,47.388805,50.902195,44.219967,50.798157z"
      />
      <path
        :style="style2"
        class="st2"
        d="M36.562683,41.10725c0.504818-0.215508,1.060543-0.334785,1.644127-0.334785
		c2.314632,0,4.191013,1.876377,4.191013,4.191013c0,2.314632-1.876381,4.191013-4.191013,4.191013
		s-4.191013-1.876381-4.191013-4.191013C34.015797,43.23243,35.065277,41.746502,36.562683,41.10725"
      />
      <path
        :style="style2"
        class="st2"
        d="M32.304958,61.938362v-4.200535c0-3.259499,2.642345-5.901844,5.901848-5.901844h0.000011
		c3.259499,0,5.901844,2.642345,5.901844,5.901844v4.221497"
      />
      <circle
        :style="style2"
        class="st2"
        cx="49.429573"
        cy="25.292166"
        r="6.28652"
      />
      <circle
        :style="style2"
        class="st2"
        cx="28.431797"
        cy="30.520962"
        r="6.28652"
      />
      <path
        :style="style2"
        class="st2"
        d="M34.525318,42.228333c-1.650772-1.447655-3.813957-2.325211-6.182108-2.325211
		c-5.180635,0-9.380373,4.199738-9.380373,9.380371v12.688332"
      />
      <path
        :style="style2"
        class="st2"
        d="M40.804302,40.703918c1.390053-3.440891,4.761974-5.868748,8.700951-5.868748
		c5.180637,0,9.380375,4.199738,9.380375,10.219177v16.868137"
      />
    </g>

    <slot />
  </svg>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Teilzeit",
  methods: {},
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style1() {
      return "fill:" + this.getState.theme.color.arrow + "; opacity: 0.5";
    },
    style2() {
      return (
        "fill:none;stroke:" +
        this.getState.theme.color.arrow +
        ";stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:0;transform: translate(0px, 0px)"
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
